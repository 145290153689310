@tailwind base;
@tailwind components;

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    font-family: "Montserrat";
    font-size: 12pt !important;
    position: relative !important;
}

body > iframe {
    pointer-events: none;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height:2px;
}

.stepsCheckbox{
    accent-color: rgb(204, 104, 40);
    width: 17px;
    height: 17px;
    border-radius: 50%;
}

.checkmarkSteps {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .stepsCheckbox:hover input ~ .checkmarkSteps {
    background-color: #fff;
  }
  
  /* When the radio button is checked, add a blue background */
  .stepsCheckbox input:checked ~ .checkmarkSteps {
    background-color: rgb(204, 104, 40);
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmarkSteps:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .stepsCheckbox input:checked ~ .checkmarkSteps:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .stepsCheckbox .checkmarkSteps:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }

.volumeIcon{
    font-size: 24px;
    margin-left: 10px;
}

.audioBack {
    width: 550px;
   background-color:  rgba(200, 102, 38, 1);
   /* background: black; */
    background-blend-mode: color;
    animation: none;
    backdrop-filter: blur(70px);
    -webkit-backdrop-filter: blur(70px);
  }

  .volume-container {
    position: relative;
  }
  
  .slide-in {
    animation: slideIn 0.5s ease-in-out;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  #range {
    position: absolute;
    right: 130px;
    width: 100%;
    padding: 10px; /* Adjust as needed */
  }
  
  
  @keyframes cssAnimation_0 {
    to {
      background-color: rgba(255, 178, 120, 1);
    }
  }

  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  
  .non-rotating-img {
position: absolute;
top: 31px;
z-index: 5;
left: 43%;  }
.audio-player {
    height: 50px;
    width: 550px;
    background: transparent;
    padding-left: 50px;
    padding-right: 50px;
    /* box-shadow: 0 0 20px 0 #000a; */
    font-family: arial;
    color: #fff;
    font-size: 0.75em;
    overflow: hidden;
    display: grid;
    grid-template-rows: 6px auto;
}

.audio-player .timeline {
    background: rgb(255, 255, 255);
    width: 100%;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    height: 50%;
    /* box-shadow: 0 2px 10px 0 #0008; */
}

.audio-player .timeline .progress {
    background: #c86626;
    width: 0%;
    height: 100%;
    transition: 0.25s;
}

.audio-player .controls {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 20px;
}

.audio-player .controls > * {
    display: flex;
    justify-content: center;
    align-items: center;
}

.audio-player .controls .toggle-play.play {
    cursor: pointer;
    position: relative;
    left: 0;
    height: 0;
    width: 0;
    border: 7px solid #0000;
    border-left: 13px solid white;
}

.audio-player .controls .toggle-play.play:hover {
    transform: scale(1.1);
}

.audio-player .controls .toggle-play.pause {
    height: 15px;
    width: 20px;
    cursor: pointer;
    position: relative;
}

.audio-player .controls .toggle-play.pause:before,
.audio-player .controls .toggle-play.pause:after {
    position: absolute;
    background: #fff;
    content: "";
    height: 15px;
    width: 3px;
}

.audio-player .controls .toggle-play.pause:before {
    top: 0;
    left: 0px;
}

.audio-player .controls .toggle-play.pause:after {
    top: 0;
    right: 8px;
}

.audio-player .controls .toggle-play.pause:hover {
    transform: scale(1.1);
}

.audio-player .controls .time {
    display: flex;
}

.audio-player .controls .time > * {
    padding: 2px;
}

.audio-player .controls .volume-container {
    cursor: pointer;
}

.audio-player .controls .volume-container .volume-button {
    height: 26px;
    display: flex;
    align-items: center;
}

.audio-player .controls .volume-container .volume-button .volume {
    transform: scale(0.7);
}

.audio-player .controls .volume-container .volume-slider {
    position: absolute;
    left: -3px;
    top: 15px;
    z-index: -1;
    width: 0;
    height: 15px;
    background: #c86626;
    box-shadow: 0 0 20px #000a;
    transition: .25s;
}

.audio-player .controls .volume-container .volume-slider .volume-percentage {
    background: coral;
    height: 100%;
    width: 75%;
}

.audio-player .controls .volume-container:hover .volume-slider {
    left: -123px;
    width: 120px;
}
.nameAudio{
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    padding-left: 50px;
    margin-bottom: 10px;
}

.custom-bullet {
    display: inline-block;
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    background: url("./assets/images/Checkmark.svg") no-repeat center center;
    background-size: contain; /* Ensure the image fits within the container */
    margin-right: 5px; /* Adjust the spacing between the image and text */
  }
  
  .packageSpan{
    display:flex;
  }

  .packageSpan span{
    display:block;
  }

  .packageSpan span:nth-of-type(1){
    min-width: 40px;
  }
  .tutoring-div{
    display: flex;
    flex-direction: row;
  }

.rangeAnimate{
    animation-name: volume;
    animation-duration: infinite;
    animation-iteration-count: 4;
    display: block;
}

.slide-in {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.show {
  opacity: 1;
}

/* 
.volume-container {
    position: relative;
  }
  
  .slide-in {
    animation: slideIn 0.5s ease-in-out;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  #range {
    position: absolute;
    right: 150px;
    width: 70%;
    padding: 10px; 
  } */

  
@keyframes volume {
    0% {
        left: 200px;
      }
      100% {
        left: 0;
      }
  }

.imgRotate{
    animation: rotateAnimation 4s linear infinite;
}

@keyframes rotateAnimation {
    0% {
      transform: rotate(0deg); /* Start with no rotation */
    }
    100% {
      transform: rotate(360deg); /* Rotate 360 degrees for one complete rotation */
    }
  }


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cc6828;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

.right-aligned {
    display: flex;
    justify-content: flex-end;
    width: 48%;
  }
  

.CookieConsent div:nth-of-type(2) {
    padding-top: 25px;
}

.disablePackages {
    pointer-events: none;
    opacity: 0.4;
}

.carousel-itemm {
    width: 680px !important;
}

.carousel-itemm {
    margin-right: 20px !important;
    padding-bottom: 20px;
}

.react-responsive-modal-modal .modal-calnd {
    height: 71vh !important;
}
.errorModalMain{
    overflow-y:unset !important ;
}
.caseSummarMain .react-responsive-modal-modal{
    width: 80% !important;

}
.caseSummarMain .signup-Modal  .react-responsive-modal-modal{
    min-width: 80% !important;

}
/* .react-responsive-modal-modal{
    min-width: 80% !important
} */
.signup-Modal{
    /* min-width: 80% !important; */
    background-color: red !important;
}
.react-responsive-modal-modal .modal-calnd .rbc-calendar {
    height: 50vh !important;
}
.badgeMain{
    display:flex;
    flex-wrap: wrap;
}
.badgeBox{
    width:115px;
}

.fullLoader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: linear-gradient(162deg, rgba(255, 196, 68, 0.2), rgba(243, 111, 86, 0.2)) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99;
    backdrop-filter: blur(11px);
}

.fullLoader svg {
    width: 100px;
    height: 100px;
}

.errorMsg {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    margin: 10px 0;
}

.dangerousSummary {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dangerousSummary > p > span {
    font-family: "Montserrat";
    font-size: 12pt !important;
}

.dangerousSummary > p > span > strong {
    font-weight: bolder !important;
    font-family: "Circular-Loom";
}

.tutorialImgWrap > span {
    display: block !important;
}

.tutorialImgWrap > span img {
    width: 100%;
}
.alink p span a {
    color: red !important;
}

.searchWrap {
    width: 400px;
    position: relative;
    z-index:991;
}

.searchWrap .css-1wy0on6 {
    display: none;
}

.searchWrap .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.searchIcon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cc6828;
    border-radius: 14px;
    position: absolute;
    top: 0;
    background: #ffffff;
    right: 0;
    width: 70px;
    height: 100%;
}

.custom-bullet {
    display: inline-block;
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    background: url("./assets/images/Checkmark.svg") no-repeat center center;
    background-size: contain; /* Ensure the image fits within the container */
    margin-right: 5px; /* Adjust the spacing between the image and text */
  }
  
  .packageSpan{
    display:flex;
  }

  .packageSpan span{
    display:block;
  }

  .packageSpan span:nth-of-type(1){
    min-width: 40px;
  }

/* Flip Module Cards Start */

.flip-card {
    background-color: transparent;
    width: 290px;
    height: 350px;
    perspective: 1000px;
    /* height: 170px; */
    overflow: hidden;
    cursor: pointer;
}
.flip-card:hover {
    /* height: 350px; */
    cursor: pointer;
    transition: 2s linear ease-in-out;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    /* transition: 2s linear ease-in-out; */
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    /* -webkit-backface-visibility: hidden;
    backface-visibility: hidden; */
    color: white;
    -webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    visibility:visible;
    backface-visibility: hidden;
}
.contentColor {
    color: grey;
}

.flip-card-front {
    /* background-color: #fff; */
    border: none;
    color: black;
    height: 100% !important;
}

.flip-card-back {
    background-color: #fff;
    color: black;
    height: auto;
    transform: rotateY(180deg);
    box-shadow: rgb(2 23 54 / 3%) 4px 8px 20px;
    border-radius: 10px;
}

.volumeIcon{
    font-size: 24px;
    margin-left: 10px;
}

.audioBack {
    width: 550px;
   background-color:  rgba(200, 102, 38, 1);
   /* background: black; */
    background-blend-mode: color;
    animation: none;
    backdrop-filter: blur(70px);
    -webkit-backdrop-filter: blur(70px);
  }

  @keyframes cssAnimation_0 {
    to {
      background-color: rgba(255, 178, 120, 1);
    }
  }

  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  .non-rotating-img {
position: absolute;
top: 31px;
z-index: 5;
left: 43%;  }
.audio-player {
    height: 50px;
    width: 550px;
    background: transparent;
    padding-left: 50px;
    padding-right: 50px;
    /* box-shadow: 0 0 20px 0 #000a; */
    font-family: arial;
    color: #fff;
    font-size: 0.75em;
    overflow: hidden;
    display: grid;
    grid-template-rows: 6px auto;
}

.audio-player .timeline {
    background: rgb(255, 255, 255);
    width: 100%;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    height: 50%;
    /* box-shadow: 0 2px 10px 0 #0008; */
}

.audio-player .timeline .progress {
    background: #c86626;
    width: 0%;
    height: 100%;
    transition: 0.25s;
}

.audio-player .controls {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 20px;
}

.audio-player .controls > * {
    display: flex;
    justify-content: center;
    align-items: center;
}

.audio-player .controls .toggle-play.play {
    cursor: pointer;
    position: relative;
    left: 0;
    height: 0;
    width: 0;
    border: 7px solid #0000;
    border-left: 13px solid white;
}

.audio-player .controls .toggle-play.play:hover {
    transform: scale(1.1);
}

.audio-player .controls .toggle-play.pause {
    height: 15px;
    width: 20px;
    cursor: pointer;
    position: relative;
}

.audio-player .controls .toggle-play.pause:before,
.audio-player .controls .toggle-play.pause:after {
    position: absolute;
    background: #fff;
    content: "";
    height: 15px;
    width: 3px;
}

.audio-player .controls .toggle-play.pause:before {
    top: 0;
    left: 0px;
}

.audio-player .controls .toggle-play.pause:after {
    top: 0;
    right: 8px;
}

.audio-player .controls .toggle-play.pause:hover {
    transform: scale(1.1);
}

.audio-player .controls .time {
    display: flex;
}

.audio-player .controls .time > * {
    padding: 2px;
}

.audio-player .controls .volume-container {
    cursor: pointer;
}

.audio-player .controls .volume-container .volume-button {
    height: 26px;
    display: flex;
    align-items: center;
}

.audio-player .controls .volume-container .volume-button .volume {
    transform: scale(0.7);
}

.audio-player .controls .volume-container .volume-slider {
    position: absolute;
    left: -3px;
    top: 15px;
    z-index: -1;
    width: 0;
    height: 15px;
    background: #c86626;
    box-shadow: 0 0 20px #000a;
    transition: .25s;
}

.audio-player .controls .volume-container .volume-slider .volume-percentage {
    background: coral;
    height: 100%;
    width: 75%;
}

.audio-player .controls .volume-container:hover .volume-slider {
    left: -123px;
    width: 120px;
}
.nameAudio{
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    padding-left: 50px;
    margin-bottom: 10px;
}


.rangeAnimate{
    animation-name: volume;
    animation-duration: infinite;
    animation-iteration-count: 4;
    display: block;
}


@keyframes volume {
    0% {
        left: 200px;
      }
      100% {
        left: 0;
      }
  }

.imgRotate{
    animation: rotateAnimation 4s linear infinite;
}

@keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg); 
    }
  }


/* fk css */
.materialSlider .react-multi-carousel-track {
    position: static !important;
}
.materialSlider .react-multi-carousel-list {
    position: static !important;
}
.materialSlider {
    height: auto;
    min-height: 500px;
}
.materialSlider li.react-multi-carousel-item.react-multi-carousel-item--active.z-10 {
    flex: 1 1 auto;
    position: relative;
    /* width: 288px !important; */
    height: 100%;
    min-height: 500px;
    margin: 0 5px !important;
}

.materialSlider .flip-card {
    background-color: transparent;
    width: 290px;
    /* min-height: 350px; */
    perspective: 1000px;
    height: 500px !important;
    margin: 0 auto;
}
.materialSlider .flip-card-back.p-4 {
   
    height: 100% !important;
    min-height: 400px;
    overflow-y: scroll;
    max-height: 400px;
    overflow-x: hidden;
}
.materialSlider .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.pkg-title{
    min-height: 135px !important;
}
.subpackgBox {
    height: 100%;
    padding-bottom: 60px !important;
    width: 95% !important;
    min-height: 185vh;
    position: relative;
}

.subpackgBox .subPckSelectBtn {
    /* position: absolute; */
    /* bottom: 2%; */
    width: 86%;
}

.flashCardBox{
    padding: 0 40px !important;
}
/* fk css end */

/* Flip Modue Cards End */

.forBoxBorder {
    /* border: 3px solid #CC6828; */
    width: 32.5% !important;
    color: #707070;
    background: #fff;
    box-shadow: rgba(2, 23, 54, 0.1) 4px 8px 20px;
    border-radius: 25px;
    cursor: pointer;
}

.buttonCnfrm {
    width: 163px !important;
    margin-top: 50px !important;
    margin: 0 auto;
}
p#content img{
    display:block;
    margin: 0 auto;
}
.checkListNowSec {
    background:#FFFAF3;
    padding:30px 20px;
    border-radius:55px;
    height: max-content;
}
.checkListNowSec .buttonClass{
    padding:5px 40px;
    text-align: center;
    border:none;
    font-weight: 700;
    line-height: 23.4px;
}
.onBlurSec{
    position:absolute;
   top:60%;
    margin: 0 !important;
    width:87% !important;
    border:none;
  

}
.onBlurSubSec{
    border-radius: 25px;
    border:2px solid #CC6828;
    padding:1.5rem;
      box-shadow: 4px 8px 20px 0px #02173608;
      background:#fff;
}
.sub-plan .infoSubBox{
    right: 0 !important;
}
.caseSumTTSubSec .infoSubBox{
    width:100% !important;
    height: 100%;
}
.caseSumTTSubSec .inInfoSubBox{
left:25px;
}

.cardClick {
    /* --tw-border-opacity: 1; */
    /* border-color: rgba(36, 119, 99, var(--tw-border-opacity)); */
}
.emailTwo{
    padding: 14px 20px !important;
    border:1px solid #D8D8D8;
    border-radius:14px;
}
.themeClr {
    color: #cc6828;
}

.linkClr {
    color: rgb(104 104 253);
    cursor: pointer;
}

.linkClr:hover {
    color: rgba(10, 10, 124, 0.776);
    cursor: pointer;
}
.pastTag{
    
    color: #fff;
    display: inline-block;
    padding: 2px 20px !important;
    background: gray;
    border-radius: 20px;
    min-width: auto;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    min-width:90px;
    float:right;
}

.upComing{
    background-color: #267864 !important;
}

.timeText {
    font-size: 19px;
    /* padding-right: 80px; */
    border-bottom: 2px solid #f5f5f5;
}
.timeText1 {
    border-top: 2px solid #f5f5f5;
}

.tutorSelect {
    border: 3px solid #cc6828;
}

.tutorSelect:hover {
    color: #cc6828;
}

.bulletSection {
    display: flex;
    justify-content: center;
    gap: 10%;
}
.bulletSection h5:nth-of-type(1)::before{
    content:'';
    width:10px;
    height:10px;
    border-radius:50%;
    background:lightgrey;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    transform: translate(0px, -1px);
}
.bulletSection h5:nth-of-type(2)::before{
    content:'';
    width:10px;
    height:10px;
    border-radius:50%;
    border: 1px solid #cc6828;
    background:#fff;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    transform: translate(0px, -1px);
}

.bookBtn {
    background: #cc6828;
    width: 140px;
    height: 50px;
    color: #fff;
}

.bookBtn:hover {
    border: 2px solid #cc6828;
    color: #cc6828;
    background: none;
}

.borderClr {
    border-color: lightgrey;
}

.partBoxOne {
    padding: 15px 25px;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
    margin-bottom: 30px;
}

.partBoxOne img {
    width: 500px;
    margin: 0 auto 30px;
}

.partBoxTwo img {
    width: 120px;
}

.partBoxP {
    font-size: 20px;
    text-align: start;
    margin-bottom: 22px;
}

.partBoxLink {
    font-size: 20px;
    color: #0275d8;
}

.partTwoH4 {
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 18px;
}

.portfolioSec {
    background-image: url("../src/assets/images/portBgNew.png");
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.portLeft svg {
    width: 4rem;
    height: 5rem;
    position: relative;
    display: block;
    fill: #c86626 !important;
    color: #c86626 !important;
}
.portLRight h2 {
    font-size: 27px;
    color: #292b2c !important;
}
.portLRight p {
    font-size: 18px;
    color: #292b2c !important;
}

.portRight .prHeading {
    font-size: 37px;
    color: #fff;
    text-align: center;
}
.portRight p {
    font-size: 18px;
    color: #fff;
}
.subscription_btn-disable {
    background-color: #c8631e !important;
    opacity: 0.7 !important;
}
.subscription_btn-primary {
    color: #c8631e !important;
    opacity: 1 !important;
    border: 1px solid rgb(142, 142, 142);
}
.subscription_btn-primary:hover {
    color: #fff !important;
    border: 1px solid #c8631e !important;
}
.learnMoreBox a {
    font-size: 22px;
}
/* .learnMoreBox a::before{
    content:"";
    width:100%;
    height: 3px;
    background: #fff;
} */
.learnMoreBox hr {
    border-top-width: 3px;
}
.valBox h1 {
    font-size: 20px;
    line-height: 22px;
}
.valBox p {
    color: #54595f;
}
.digitalMain {
    background: #fff !important;
}
.digBoxLeft h1 {
    font-size: 38px;
    margin-bottom: 50px;
    line-height: 45px;
}
.digBoxLeft p {
    color: #54595f;
    font-size: 19px;
    line-height: 30px;
    margin-bottom: 22px;
}
.digBoxRight h2 {
    font-size: 28px;
}
.digBoxRight p {
    font-size: 18px;
}
.teamInfoSec h3 {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 15px;
}
.teamInfoSec h3 span {
    font-size: 18px;
    color: #54595f;
    font-weight: 600;
}
.teamInfoSec p {
    font-size: 17px;
    color: #54595f;
    line-height: 28px;
}
p.paidNotSec {
    min-width: 85px;
    text-align: center;
}
label.pnpModel {
    margin-right: 10px;
    min-width: 100px;
}
/* time line css  */
/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul {
    background: #fff;
    padding: 50px 0;
}

.timeline ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: #c86626;
}

.timeline ul li::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: inherit;
    z-index: 1;
}
.timeline ul .timeBoxOne::after {
    content: "1";
    text-align: center;
    padding-top: 4px;
    font-weight: 700;
}
.timeline ul .timeBoxTwo::after {
    content: "2";
    text-align: center;
    padding-top: 4px;
    font-weight: 700;
}
.timeline ul .timeBoxThree::after {
    content: "3";
    text-align: center;
    padding-top: 4px;
    font-weight: 700;
}
.timeline ul .timeBoxFour::after {
    content: "4";
    text-align: center;
    padding-top: 4px;
    font-weight: 700;
}

.timeline ul li div {
    position: relative;
    bottom: 0;
    width: 25vw;
    padding: 15px;
    background: #f1f1f1;
}

.timeline ul li div::before {
    content: "";
    position: absolute;
    bottom: 7px;
    width: 0;
    height: 0;
    border-style: solid;
}

.timeline ul li:nth-child(odd) div {
    left: 45px;
}

.timeline ul li:nth-child(odd) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #f1f1f1 transparent transparent;
}

.timeline ul li:nth-child(even) div {
    left: -480px;
}

.timeline ul li:nth-child(even) div::before {
    right: -15px;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent #f1f1f1;
}

time {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
}

/* EFFECTS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul li::after {
    transition: background 0.5s ease-in-out;
}

.timeline ul li.in-view::after {
    background: #fff;
    box-shadow: 0 0 15px #ff884c;
}

.timeline ul li div {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
    transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
    transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
    transform: none;
    visibility: visible;
    opacity: 1;
    font-size: 20px;
}
.backToSearchBtn div:hover{

    background: transparent !important;
border-color: #4D4349 !important;
opacity: 0.7;
    
}
.backToSearchBtn div p:hover{
    /* color: #fff; */
    opacity: 0.7;
border-color: #4D4349 !important;
    


    
}
.tooltipSec div{
    height: auto !important;
}
h1.entry-title{
    text-align: center;
}
.essayBox{
    text-align: center;
}
div#worker {
    width: 100% !important;
    margin: 0 auto !important;
    overflow-x: scroll;
    /* padding: 0 !important; */
}
.timeSlotHead{
    padding: 40px 0px;
}
.headDropSec img{
    height: 45px;
}
.wayPossSec {
    gap: 5%;
}
.selectSessText{
    color: #c8631e !important;
}
.selected-slots{
    border-color: #c8631e !important;
      color: #c8631e !important;
}
.selectBokMain{
    align-items: center;
}
.toolTipMain{
      width: 100% !important;
      z-index:99 !important;
}
.rpv-core-popover-overlay {
    bottom: 0;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
}

.tooltipSSec{
    z-index:9999999 !important;
    width: 100% !important;
}
.tooltipSec div{
    width: 300px !important;
}
.toolTipSubSec{
    width: 100% !important
}
/* .tooltipSubSec .ql-block{
    display:inline-block;
    margin-right:1px;
} */
.qblockBox div{
    display:inline;
}
.cardTitleSec{
    height: 100px;
}
.tSlotHeadLeft{
    width:40%;
}
.tsShowCalSec {
    width: 60%;
    position: relative;
    display: flex;
    grid-gap: 2%;
    gap: 1%;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
}
.tsShowCalSec .rdrDateRangePickerWrapper {
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;
    position: absolute;
    /* left: 0; */
    top: 100px;
    background: #fff;
    z-index: 9999999999999999;
    right: -30% !important;
}
.calndrSvg{
    animation-name: svgIcon;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
    /* box-shadow: 0 0 5px #c8631e; */
    animation-timing-function: linear;
    /* border-radius: 50%; */
    background:transparent;
}


@keyframes svgIcon {
 0% {
    transform: scale(1.1)

 }   
 30% {
    transform: scale(1.3)
 }  
 /* 40% {
    transform: scale(1.3)
 }   */
 60% {
    transform: scale(1.4)
 }  
 /* 80% {
    transform: scale(1.6)
 }   */
 100% {
    transform: scale(1.6)
 }  
}

.cirBarSpanMain text.CircularProgressbar-text{
    fill : #fff !important;
}
.cirBarSpanMain path.CircularProgressbar-path{
    stroke:#fff !important;
}
.cirBarSpanMain path.CircularProgressbar-trail{
    stroke: #c8631e !important;
}

.cirBarSpanMain text.CircularProgressbar-text:hover{
    fill : #c8631e !important;
}
div#freetrial:hover .cirBarSpanMain text.CircularProgressbar-text{
    fill : #c8631e !important;
}

div#freetrial:hover .cirBarSpanMain path.CircularProgressbar-trail{
    stroke: #fff !important;
}
.grey-top{
    border-top: 21px solid #D8D8D8;
    border-radius: 21px 21px 0px 0px;
}
.purple-top{
    border-radius: 21px 21px 0px 0px;
    border-top: 21px solid #87326B;
}
.currency-sign{
    left: -1.5rem !important;
}
/* sub tab css  */
.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}
/* sub tab css end */
.subTabsMain {
    padding-top: 50px;
    padding: 50px 220px;
}

.tab.active{
    background: #EEEEEE;
    color: #434343;
    font-weight: 700;
}
.tab{
    background: #fff;
    margin: 0 15px;
    width: 250px;
    box-shadow: 4px 8px 20px 0px rgba(2, 23, 54, 0.03);

}
.testiSliderMain{
    /* background: #fff;
    box-shadow: 4px 8px 20px 0px rgba(2, 23, 54, 0.03);
    border-radius: 25px; */
    position: relative;
}
.testiSliderMain .react-multi-carousel-list{
    background: #fff;
    box-shadow: 4px 8px 20px 0px rgba(2, 23, 54, 0.03);
    border-radius: 25px;
    position: static;
}

.testiSliderMain .slide-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.testiSliderMain .slide-content{
    font-size: 18px;
    font-weight: 400;
    color: #434343;
    margin-top: 15px;
}
.testiSliderMain .slide-content h5{
    font-weight: 700;
}
.testiSliderMain .react-multiple-carousel__arrow{
    background: #CC6828 ;
}
.rating-stars svg{
    color: #CC6828;
}
div#freetrial:hover .cirBarSpanMain path.CircularProgressbar-path{
    stroke:#c8631e !important;
}

.bell-icon{
    color: #CC6828;
    cursor: pointer
}

.trashcan{
    color: #CC6828;
    cursor: pointer;
    font-size: 24px;
    margin-right: 40px;
}

.bank-icon-border{
    color: #CC6828;
}


.testiFooterText{
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
}
.testiFooterText a{
  
}
p.testiFooterText a {
     color: #c8631e !important;
     text-decoration: underline;
}
.faqsMain .accordion {
    width: 100%;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.faqsMain .item {
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  padding: 20px;

  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 24px;
  row-gap: 30px;
  align-items: center;
  cursor: pointer;
    border-radius: 25px;
}

.faqsMain .number,
.faqsMain .text {
  font-size: 18px;
  font-weight: 400;
}

.number {
  /* color: #ced4da; */
}

.faqsMain .icon {
  width: 24px;
  height: 24px;
  stroke: #c8631e;
  cursor: pointer;
}

.faqsMain .hidden-box {
  grid-column: 2;
  display: none;
}

.faqsMain .hidden-box p {
  line-height: 1.6;
  margin-bottom: 24px;
}

.faqsMain .hidden-box ul {
  color: #868e96;
  margin-left: 20px;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* OPEN STATE */
.faqsMain .open {
  border-top: 1.7px solid #c8631e;
  border-radius: 25px;
      box-shadow: 4px 8px 20px 0px rgba(2, 23, 54, 0.03);
}

.faqsMain .open .hidden-box {
  display: block;
}

.faqsMain .open .number,
.faqsMain .open .text {
  color:#434343
;

}
div#select{
    height: auto !important;
}
div#selected{
    height: auto !important;
}
.subpackgBox .selectBtnBox{
    position: absolute;
    bottom: 10px;
    width: 80%;
    left: 10%;

}
.selectBtnBox div{
    width: 100%;
}


.FreeTrailBtnSec{
    display:flex;
    align-items: baseline;
    position: relative;
    width: 220px;
}
.FreeTrailBtnSec1{
    display:flex;
    align-items: baseline;
    position: relative;
    width: 220px;
}

.FreeTrailBtnSec .toolTipMain{
    position: absolute;
    top:10px;
    left:-30px;
    z-index:9 !important;
}
.FreeTrailBtnSec1 .toolTipMain{
    position: absolute;
    top:10px;
    left:-30px;
    z-index:9 !important;
}
.FreeTrailBtnSec .inInfoSubBox {
    display: none;
}
.FreeTrailBtnSec .infoSubBox{
    height: auto !important;
    width:480px;
    text-align: center;


}
.FreeTrailBtnSec1 .infoSubBox{
    height: auto !important;
    width:480px;
    text-align: center;


}
.FreeTrailBtnSec .tooltipSSec {
    z-index: 9999999 !important;
    width: 100% !important;
    position: absolute;
    right: 100px !important;
    top: 40px;
}
.FreeTrailBtnSec1 .tooltipSSec {
    z-index: 9999999 !important;
    width: 100% !important;
    position: absolute;
    right: 100px !important;
    top: 40px;
}
.testiSLiderWrapper .react-multiple-carousel__arrow--left {
    /* left: calc(1.5% + 1px); */
       /* right: calc(4.5% + 10px); */
    left:37%;
    top:-10%

}
.testiSLiderWrapper .react-multiple-carousel__arrow{
    min-height: 25px;
    min-width:25px;
    /* top: 5px; */
}
.testiSLiderWrapper .react-multiple-carousel__arrow--left::before{
    content:"\e824";
    font-size:12px;
}
.testiContentP{
    font-size:13px;
}
.testiSLiderWrapper .react-multiple-carousel__arrow--right {
    /* right: calc(1.5% + 1px) !important; */
         /* right: calc(1.5% + -40px);
    left:unset; */

    right:37%;
    top:-10%
}
.testiSLiderWrapper .react-multiple-carousel__arrow--right::before {
    content: "\e825";
    font-size:12px !important;
}
.materialSlider .react-multiple-carousel__arrow--left{
    left: 100px !important;
}

div#blur_quiz{
    position: relative;
}
#blur_quiz .quizBlurSection {
    filter: blur(0px) !important;
}
.quizBlurSection{
    position: fixed;
    bottom: 300px;
    left: 50%;
    transform: translate(-50%, 0px);
    width:50% !important;
    z-index:1;
    min-width:500px;
    
}
.quizBlurSection .onBlurSubSec > p{
    font-weight: 600 !important;
}
.infoAboutMain{
    display:flex;
    column-gap:15px;
}
.infoAboutMain .info-about {
    color: #8e8e8e;
    font-size: 17px;
    margin-left: 5px;
    width: 310px;
}
.infoAboutMain .FreeTrailBtnSec .toolTipMain {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9 !important;

}
.infoAboutMain .FreeTrailBtnSec1 .toolTipMain {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9 !important;

}
.notesSecMain{
    column-gap:25px;
}
.abcMain #summary:nth-of-type(1) {
    display: none;
}
.abcMain #blur:nth-of-type(1) {
    display: none;
}
.blurMain #blur:nth-of-type(1) {
    display: none;
}
.blurMain #summary:nth-of-type(1) {
    display: none;
}

#blurContent #blur:nth-of-type(1){
    display: none;
}

#blurContent #summary:nth-of-type(1){
    display: none;
}

/* one to one payment step 3 css  */
.savePaymentSec{
    column-gap:15px;
    margin-top:50px;
}
.paymentImgSec{
    width:20%;

}
.paymentContsec{
    flex:1;

}
.paymentContsec h2{
    font-weight: bold;
    margin-bottom: 20px;

}
.paymentContsec h4{
   
    margin-bottom: 20px;
    font-size:14px;

}
.paymentContsec a{
   
    text-decoration: underline;
color:#0275d8;
font-size:13px;
display:flex;
}
.paymentContsec a img{
   
  width:20px;
  height:20px;
  display:inline-block;
}



.cases{
    min-width:350px !important;
}

.free-tool{
    right:224px !important;
}


/* side-bar */
.chat-side{

    border-bottom:1px solid #F2F3F5;
    padding: 10px;
    display:flex;
    column-gap:10px;
}
.chatSideLeft{
    width:50px !important;
    display:flex;
    align-items: center;
}
.chatSLImgSec{
    width:45px;
    /* height:100%; */
}
.chatSLImgSec img{
    width: 35px;
    height:35px;
}
.chat-side:hover{
    background-color: rgba(225, 225, 225, 0.869);
}
.chatSideRight{
    flex:1;

}
.chatSideRight > div{
    display:flex;
    align-items: flex-start;
    
}
.chatSideRight p{
    font-size: 13px;
    font-weight: normal;
    margin-top: 4px;
    color: #a6a6a6;

}
.head-text-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;

}
.notification-head{
    align-items: center;
    display: flex;
}

.for-txt{
    overflow: hidden;
    width: 100%;
 
}
.for-txt p{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: nowrap; 
}
.head h2{
    color: #CC6828;
    font-size: 18px;
}
.side-bar{
    position: absolute;
    top:100%;
    right:0%;
    background-color: #fff;
    z-index: 999;
    width:25%;
    border:1px solid #F2F3F5;
    padding: 20px 0;
    height:300px;
    overflow-y: scroll;
    transition: 0.2s;
}

.side-bar {
    --scrollbarBG: #CFD8DC;
    --thumbBG: #CC6828;
  }
  .side-bar::-webkit-scrollbar {
    width: 11px;
  }
  .side-bar {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .side-bar::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .side-bar::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
   .sideBarSubSec{
    position: relative;
    padding: 30px 10px 10px;
   }
.close-icon {
    position: absolute;
    top: 0px;
    right: 25px;
    float: right;
    cursor: pointer;
    font-size: 30px;
    color: #CC6828;
  }
  
  /* Additional styles for closing the side-bar */
  .side-bar.closed {
    display: none;
  }


.side{}
.StepButton-d14-1-2-32.completed{
    background-color: pink !important;
}

.StepButtonContent-d1-0-2-7.completed{
    color: transparent;
}
.StepButtonContent-0-2-5 {
    display: none !important;
}
.StepButton-0-2-4.active {
    background-color: #CC6828 !important;
}
.StepButton-0-2-4.completed {
    background-color: #CC6828 !important;
}
.Label-0-2-9 {
    color: #CC6828 !important;
}
.stepper-ul{
    list-style:disc;
    padding: 10px 10px 10px 20px;
}
.stepper-ul li{
    margin-bottom: 13px;
}

.burger-menu {
    display: none; 
  }
  .burger-menu{
    z-index: 99;
    width: 200px;
    height: 100vh;
    position: absolute;
    top: -35px;
    left: -20px !important;
    background-color: white;
    padding: 20px;
    box-shadow: rgb(2 23 54 / 3%) 4px 8px 20px;


  }
  .burger-menu.open {
 
    
    display: block; 
  }
.info-icon-img{
    /* position: absolute !important; */
    /*  */
    /* top: -19px !important; */
    
}

.upgrade-toolTipMain{
    left:-100px !important
}
.sessRightSec{
    min-width:170px;
}
.tutorEditIcon{
    min-width: 20px;
}

    .callToAct{
        display:flex;
        justify-content: space-between;
        position: relative;
        bottom:0;
        padding: 30px 120px 20px 60px;
        background: #fff;
        min-height: 300px;
        width:100%;
        max-height: 600px;
        overflow-y: hidden;
    }
    .callToActLeft{
        width: 34%;
        
    }
    .callToActLeft img{
        margin-bottom: 25px;
    }
    .callToActLeft p{
        font-size: 16px;
        padding-left: 12px;
        font-weight: 600;

    }
    .callToActRight{
        width: 63%;
        display: flex;
        /* justify-content: space-between; */
        gap: 30px;
        justify-content: flex-end;
        align-items: flex-start;
    }
    .ctaCardHead{
        margin-bottom: 15px;
    }
    .ctaCardHead h4{
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
        font-size: 18px;
        font-weight: 700;
    }
    .ctaCard{
        width: 42%;
        min-height: 150px;
        box-shadow: 0 0 4px #CC6828;
        background:#fff;
        padding: 12px 15px 15px;
        border-radius: 8px;
    }
    .ctaCard .packageSpan span:nth-of-type(1) {
        min-width: 25px;
    }
    .ctaListSec .packageSpan span {
        display: block;
        font-size: 13px;
    }
    .ctaSignUpBtn{
        background: #CC6828;
        color:#fff;
        width: 100%;
        padding: 5px;
        border-radius: 18px;
        margin-top: 10px;

    }
.navitem-custom-tooltip {
    visibility: hidden;
    background-color: black;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%);
    left:60px;
    white-space: nowrap;
}
.navitem-custom-tooltip span {
    color: white;
    display: block;
    line-height: normal;
    font-size: 14px;
}
.navitem-custom-tooltip::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
@media only screen and (min-width: 767.98px) {
    .navItem:hover .navitem-custom-tooltip {
        visibility: visible;
        opacity: 1;
    }   
}
/* GENERAL MEDIA QUERIES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
 @media screen and (min-width:560px)
 {
    .burger-menu{
        top:-60px !important;
        width: 230px !important;
        left: -40px !important;
    }

 
 }
 
 @media screen and (max-width:480px){
    .FreeTrailBtnSec .infoSubBox{
      
        width: 280px !important;
    }
    .FreeTrailBtnSec1 .infoSubBox{
        width: 280px !important;
    }
   
 }
  @media screen and (max-width: 560px) {
    .text-h3{
        font-size: 16px !important;
    }
    .FreeTrailBtnSec .infoSubBox{
        width: 300px ;
    }
    .FreeTrailBtnSec1 .infoSubBox{
        width: 300px ;
    }
    .FreeTrailBtnSec .tooltipSSec{
        right: unset !important;
        /* left: -135% !important; */
    }
    .FreeTrailBtnSec1 .tooltipSSec{
        right: unset !important;
        left: -60px !important;
    }
    .pkg-title{
        min-height: 50px !important;
    }
    
    
  }
@media screen and (max-width: 900px) {
    .subBoxMain .tickBulletSec .tooltipSSec .infoSubBox {
   
        width: 320px !important;
        min-width:auto;
        margin: 0 auto;
        z-index: 1000;
       
    }
    .FreeTrailBtnSec .infoSubBox{
       
        width:400px;
        text-align: center;
    
    
    }
    .FreeTrailBtnSec1 .infoSubBox{
       
        width:400px;
        text-align: center;
    
    
    }
    .socials-text{
        width: 300px;
    }
    .timeline ul li div {
        width: 250px;
    }
    .timeline ul li:nth-child(even) div {
        left: -289px;
        /*250+45-6*/
    }

    .margin-story{
        margin-bottom: 40px !important;
    }
}

@media print {
    body {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .timeline ul li {
        margin-left: 20px;
    }
    .timeline ul li div {
        width: calc(100vw - 91px);
    }
    .timeline ul li:nth-child(even) div {
        left: 45px;
    }
    .timeline ul li:nth-child(even) div::before {
        left: -15px;
        border-width: 8px 16px 8px 0;
        border-color: transparent #f45b69 transparent transparent;
    }
    
}


@media screen and (max-width: 480px) {
    .wayPosInfoBox{
        left:-20px !important
    }
}
/* EXTRA/CLIP PATH STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline-clippy ul li::after {
    width: 40px;
    height: 40px;
    border-radius: 0;
}

.timeline-rhombus ul li::after {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
    bottom: 12px;
}

.timeline-star ul li::after {
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.timeline-heptagon ul li::after {
    clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
}

.timeline-infinite ul li::after {
    animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
    0% {
        transform: translateX(-50%) scale(1);
    }
    50% {
        transform: translateX(-50%) scale(1.25);
    }
    100% {
        transform: translateX(-50%) scale(1);
    }
}

/* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
    position: fixed;
    right: 0;
    bottom: 20px;
    display: flex;
    align-items: center;
    padding: 5px;
    color: black;
    background: rgba(255, 255, 255, 0.65);
}

.page-footer a {
    display: flex;
    margin-left: 4px;
}
/* time line css end  */

@media (min-width: 320px) {
    .sm\:w-64 {
        width: 17rem !important;}
    .carousel-containers {
        padding-left: 0;
    }

    .carousel-itemm {
        width: 292px !important;
        margin-right: 10px !important;
    }
}

canvas.react-pdf__Page__canvas {
     width: max-content !important;
    height: 100% !important; 
    /* margin-left: -108px !important; */
}

.rpv-core-text-layer {
    user-select: none !important;
}

#blur > p:nth-child(n + 8) {
    filter: blur(4px);
}
.blurred {
    filter: blur(4px);
}

#blur_quiz > div:nth-child(n + 4) {
    filter: blur(4px);
}

#blurNote > span:nth-child(n + 5) {
    filter: blur(4px);
}

.mainPDF {
    position: relative;
}

.body-maintenence {
    text-align: center;
    font: 20px Helvetica, sans-serif;
    color: #333;
    padding: 150px;
}
.article-maintenence {
    display: block;
    text-align: left;
    width: 650px;
    margin: 0 auto;
}

.a-maintenence {
    color: #dc8100;
    text-decoration: none;
}
.h1-maintenence {
    font-size: 50px;
}
.wordpress-link-maintenence {
    color: #fff;
    background-color: #dc8100;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    font-size: 20px;
    text-decoration: none;
}


.condContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #434343;
    font-weight: 400;
}
.condContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #8e8e8e;
}
.condContainer:hover input ~ .checkmark {
    opacity: 0.8;
}
.condContainer input:checked ~ .checkmark {
    background-color: #cc6828;
    border: none;
}
.tickBulletSec-infoicon{
    left: 0px !important;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.condContainer input:checked ~ .checkmark:after {
    display: block;
}
.condContainer .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.login-alert {
    color: red;
}

.userContSec {
    max-height: 925px;
    overflow: hidden;
    overflow-y: auto;
}

.blurBox {
    height: 519px;
    width: 100%;
    position: absolute;
    top: 44%;
    left: 0;
    backdrop-filter: saturate(100%) blur(4px);
}
.fontWeightQuiz {
    font-size: 22px !important;
}
.selected-slots {
    width: 24%;
}
.date-time {
    width: 74%;
}
form.resetPswrdForm {
    position: relative;
}
form.resetPswrdForm .text-input{
    padding-right: 35px !important;
    
}
@media (min-width: 768px) {
    .carousel-containers {
        padding-left: 0;
    }

    .carousel-itemm {
        width: 502px !important;
    }
   
}
@media screen and (min-width:781px) {
    .socials-text{
        width: 300px;
    }
}
@media (min-width: 1500px) {
    .socials-text{
        width: 500px;
    }
    .carousel-containers {
        padding-left: 221px;
    }

    .carousel-itemm {
        width: 680px !important;
    }

    .carousel-containers {
        padding-left: 221px;
    }
}
.planCardMain {
    width: 100%;
}
.subpackgMain{
    display: flex !important;
    justify-content: space-between;
    /* flex-wrap:wrap !important; */
    row-gap: 10px !important;
}
.subpackgMain > div{
    width: 50%;

}

.rpv-toolbar-right > .rpv-toolbar-item:nth-child(3) {
    display: none;
}

.rpv-toolbar-right > .rpv-toolbar-item:nth-child(4) {
    display: none;
}
.rpv-toolbar-right > .rpv-toolbar-item:nth-child(2) {
    display: none;
}

.buttonClass {
    color: #fff !important;
    background: #cc6828;
    opacity: 1;
}

.buttonClass:hover {
    background-color: #fff !important;
    color: #cc6828 !important;
    border: 1px solid #cc6828 !important;
    opacity: 1 !important;
}

.queryBtn {
    max-width: 15% !important;
    font-size: 20px !important;
    margin: 0 auto;
    min-height: 43px;
    padding: 0 !important;
}

html {
    scroll-behavior: smooth;
}

.cookiesPolicy {
    margin-top: 30px;
    padding: 5px 40px;
}

.policyPara {
    font-size: 20px;
}

.listPolicy {
    list-style: auto;
    font-size: 20px;
    text-align: justify;
}

.privacyTableContent {
    width: 100%;
    margin-top: 20px;
    background-color: #c8631e;
}

.privacyTableContent p {
    color: white;
    font-size: 20px;
    padding: 10px;
}

.linear-color {
    background: linear-gradient(161.96deg, #c8631e 3.8%, #c8631e 231.69%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.linear-background {
    background: linear-gradient(to right, #c8631e 3.8%, #c8631e 231.69%);
}

/*wayposs section css */
.wayPossImg {
    transform: translate(5px, 3px);
}

.wayPosInfoBox {
    position: absolute !important;
    top: 10px !important;
    left: 0 ;
    height: auto !important;
    z-index: 99999 !important;
    width: 100% !important;
    padding: 5px 10px !important;
}

.scheduleBtn {
    position: absolute;
    top: 5px;
    right: 30px;
    background-color: #c8631e;
    padding: 5px 20px 5px 20px;
    color: white;
    border-radius: 0;
    margin-bottom: 15px;
}

@layer base {
    body {
        @apply bg-main font-ubuntu;
    }

    .btn {
        @apply text-center;
    }

    .input-group {
        @apply border-2 flex flex-wrap;
        align-items: stretch;
    }

    .input-group input {
        @apply border-0 flex-auto;
    }

    .input-group input:focus-visible {
        @apply outline-none;
    }

    input,
    button,
    .btn,
    .input-group {
        @apply rounded-xl;
    }

    .input-group-append,
    .input-group-prepend {
        @apply flex border-r-2 border-t-2 border-b-2 lg:border-l-2 rounded-r-xl rounded-b-xl lg:rounded-xl lg:border-primary px-4 lg:px-6;
        margin-left: -30px;
    }

    .hidden {
        -moz-animation-name: fadeIn;
        -webkit-animation-name: fadeIn;
        -ms-animation-name: fadeIn;
        animation-name: fadeIn;
        -moz-animation-duration: 1s;
        -webkit-animation-duration: 1s;
        -ms-animation-duration: 1s;
        animation-duration: 1s;
        -moz-animation-timing-function: ease-in-out;
        -webkit-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -moz-animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        -ms-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
}

@tailwind utilities;


.adminSearchWrap{
    min-width:450px ;
    z-index:2 !important;
    
}
.searchWrap2{
    min-width:450px ;
    z-index:2 !important;
    
}
.adminSearchWrap::placeholder{
    font-size: 12px !important;
}
.adminSearchWrap div#react-select-2-placeholder{
    font-size: 12px !important;
}
.tickBulletSec{
    margin-bottom:20px;
    display: flex;
    align-items: center;
    gap:3%;
    position: relative;
    justify-content: space-between;
    width:100%;
}
.tickBulletSec3{
    margin-bottom:20px;
    display: flex;
    align-items: center;
    gap:3%;
    position: relative;
    justify-content: space-between;
    width:100%;
}
.tickBulletSec1{
    margin-bottom:20px;
    display: flex;
    align-items: center;
    gap:3%;
    position: relative;
    justify-content: space-between;
    width:100%;
}
.tickBulletSec  svg{
    height:40px;
    width: 40px;fill: darkgreen;
    

}
.tickBulletSec1  svg{
    height:40px;
    width: 40px;fill: darkgreen;
    

}
.tickBulletSec3  svg{
    height:40px;
    width: 40px;fill: darkgreen;
    

}

.flex-basis-40{
flex-basis: 40%;
}
.flex-basis-60{
    flex-basis: 60%;
    }
.borderMessage{
    border-left: 8px solid #cc6828;
    border-top: 1px solid #cc6828;
    border-right: 1px solid #cc6828;
    border-bottom: 1px solid #cc6828;
    border-radius: 15px;
    padding: 20px;
}

.tickBulletSec .toolTipMain {
    width: 5% !important;
    /* z-index: 1 ; */
    /* position: absolute; */
    position: relative;
    min-width:20px;
right: -47px;
    
}
.tickBulletSec1 .toolTipMain {
    width: 5% !important;
    /* z-index: 1 ; */
    /* position: absolute; */
    position: relative;
    min-width:20px;
    right: -26px;

    
}
.tickBulletSec3 .toolTipMain {
    width: 5% !important;
    /* z-index: 1 ; */
    /* position: absolute; */
    position: relative;
    min-width:20px;
    left: 0px !important;
    
}
.toolTipMain{z-index: 1 !important;}
.tickBulletSec .toolTipMain img{
    /* float:right; */
    /* z-index:8  !important; */
    width:100%;
    height:auto;
    z-index:1;
}
.tickBulletSec1 .toolTipMain img{
    /* float:right; */
    /* z-index:8  !important; */
    width:100%;
    height:auto;
    z-index:1;
}
.tickBulletSec3 .toolTipMain img{
    /* float:right; */
    /* z-index:8  !important; */
    width:100%;
    height:auto;
    z-index:1;
}
.tickBulletSec .toolTipMain .tooltipSSec .infoSubBox{
    position: absolute;
    right: -20px;
    top: 19px;
    display: block;
    width: 100% !important;
    z-index: 9999 !important;
    min-width: 500px;
    height: auto !important;
    background:#fff;
}
.tickBulletSec1 .toolTipMain .tooltipSSec .infoSubBox{
    position: absolute;
    right: -20px;
    top: 19px;
    display: block;
    width: 100% !important;
    z-index: 9999 !important;
    min-width: 400px;
    height: auto !important;
    background:#fff;
}
.tickBulletSec3 .toolTipMain .tooltipSSec .infoSubBox{
    position: absolute;
    right: -20px;
    top: 19px;
    display: block;
    width: 100% !important;
    z-index: 9999 !important;
    min-width: 400px;
    height: auto !important;
    background:#fff;
}


/* .tickBulletSec .tooltipSSec .infoSubBox {
    width: 100% !important;
    height: 100% !important;
    z-index: 99999 !important;
    min-width:300px;
    height:auto !important;
    background:#fff;
} */
.tickBulletSec h5{
    flex:1;
}
.tickBulletSec1 h5{
    flex:1;
}
.tickBulletSec3 h5{
    flex:1;
}
.tickBulletSec .tooltipSSec .infoSubBox img{
    display: none;
}
.tickBulletSec1 .tooltipSSec .infoSubBox img{
    display: none;
}
.tickBulletSec3 .tooltipSSec .infoSubBox img{
    display: none;
}
.tickBulletSec .inInfoSubBox img{
    display:none !important;
}
.tickBulletSec1 .inInfoSubBox img{
    display:none !important;
}
.tickBulletSec3 .inInfoSubBox img{
    display:none !important;
}
.css-12ii80c-placeholder {
    
    font-size: 15px !important; 
}
.quizResultMain .BoostPWrapper{
    display:none;
}
.tagsRow{
    width:100%;
    flex-wrap: nowrap;
}
.tagsRow  li{
    width:33.3%;
    margin-top:15px;
    
}
.tagsRow  li button{
    width:100%;
    
}
@media(min-width: 1501px){
   /* .materialSlider li.react-multi-carousel-item.react-multi-carousel-item--active.z-10 {
    
    margin: 0 15px !important;
} */
    
}


@media (max-width:1024){
    .secSearch{
        display:none  !important;
    }
}


/* media query start  */
@media (min-width: 1399px) {
    .flipMain {
        justify-content: space-between;
    }
    /* .sideBarMain{
        display: none !important;
    } */
    div#freetrial {
        font-size: 16px !important;
    }
    .subTabsMain {

    padding: 40px 20px;
}
}

@media (max-width:1399px){
    div#claimyour\ free\ tutoring\ for\ 1\ hour {
        font-size: 14px !important;
    }
    .adminSearchWrap{
        min-width:410px 
        
    }
    .searchWrap2{
        min-width: 410px
    }
    .adminSearchWrap .css-1jxare7-control{
        padding-left:2px !important;
    }
    .searchWrap2 .css-1jxare7-control{
        padding-left:2px !important;
    }
    .respHeader .nav-item {
        /* padding: 5px !important; */
        padding-right: 5px !important;
        margin-right: 0 !important;
        font-size: 14px !important;
    }
    .searchIcon {
        
        width: 50px;
        height: 100%;
        border-radius: 10px;
    }
    .searchIcon img{
        max-width:20px;
    }
    .logoImg{
        max-width: 85%;
    }
    /* .sideBarMain{
        display: none !important;
    } */
    div#freetrial {
        font-size: 15px !important;  
    }
    .caseSummarSubMain .emailTwo::placeholder{
font-size: 13px;
}
.testiSliderMain .slide-content {
    font-size: 16px;
    padding: 10px 12px;
}
.testiSliderHeading{
    font-size: 20px !important;
}
.testiSLiderWrapper .react-multiple-carousel__arrow--left {
    /* left: calc(1.5% + 1px); */


    /* right: calc(2.5% + 10px); */
    
}
.onBlurSubSec {
  
    padding: 0.8rem;
  
}
.cardTitleSec p{
    font-size: 17px !important;
}
}

@media (max-width: 1199px) {
    .text-input {
        height: 47px !important;
    }
    .form-control {
        height: 47px !important;
    }
    canvas.react-pdf__Page__canvas {
        width: 100% !important;
    }
    /* .react-responsive-modal-modal {
   
    padding: 35px 40px !important;
    min-width: 648px !important;
} */
    /* .react-tel-input .form-control{
    height:45px;
} */
.react-responsive-modal-modal {
 
    padding: 40px 55px !important;

}
.tutorTabs {
    margin-top: -40px;
}
div#session-container > div > div{
    padding: 10px 10px;
}
/* div#session-container > div > div text-h5{
    font-size:17px;
} */

.userContSec .dayMonthSec p {
    font-size: 20px;
}
.userContSec .appointTitle{
    font-size: 18px !important;
}
.userContSec .freeGroupSec p {
    font-size: 16px;
}
label.pnpModel {
    
    font-size: 18px !important;
}
.timeText {
    font-size: 18px;
    /* padding-right: 50px; */
    border-bottom: 2px solid #f5f5f5;
}
.text-h5 {
    font-size: 19px !important;
}
.BoostPWrapper {
    padding: 0 50px !important;
}
.css-1jxare7-control{
    height: 48px !important;
}
.materialSlider.pb-10.md\:pb-32.xl\:pb-24 {
    height: auto;
    min-height: 500px;
    padding-bottom: 0 !important;
}
.socialBtn{
    height: 48px !important;
}
.caseHeadText{
    font-size: 33px;
}
.essayBox p{
   font-size: 18px;
}
div#summary h1 {
    font-size: 28px !important;
}
.tipsHeadText h2{
    font-size: 28px;
}
.policyPara {
    font-size: 18px;
}
.portLRight h2 {
    font-size: 25px;
  
}
.portLRight p {
    font-size: 17px;
   
}
.portLeft svg {
    width: 3.8rem;
    height: 4.8rem;
 
}
.portRight .prHeading {
    font-size: 35px;
   
}
.portLeft {
    padding: 60px 40px;
}
.learnMoreBox a {
    font-size: 20px;
}
.cMatrialText{
    font-size: 30px !important;
    padding: 20px 0px 10px !important;
}
.timeline ul li:nth-child(even) div {
    left: -325px;
}
.timeline ul li.in-view div {
   
    font-size: 18px;
}
.timeline ul li {
    
   
    padding-top: 26px;
 
}
.valueText{
    font-size:30px !important;
}
.valBox h1 {
    font-size: 18px;
   
}
.valBox p {
   
    font-size: 15px;
}
.digiH1Text{
    font-size: 26px !important;
}
.digBoxRight h2 {
    font-size: 26px;
}
.teamInfoSec p {
    font-size: 16px;
    
}
.meetTeamtext{
    font-size: 30px !important;
}
.teamInfoSec h3 {
    font-size: 21px;
  
    margin-bottom: 12px;
}
.partBoxOne img {
    width: 400px;
    margin-bottom: 25px;
 
}
.pTitleText{
    font-size: 27px !important;
}
.partBoxP {
    font-size: 18px;
    margin-bottom: 17px;
}
.partBoxLink {
    font-size: 18px;

}
.ourPrtnerText{
    font-size: 27px !important;
}
.termCondText{
    font-size: 30px !important;
}
.privacyTableContent p {
  
    font-size: 18px;
    padding: 9px;
}
.termCondOlSec ol{
    font-size:19px !important;
}
section#introduction p {
    margin-top: 10px !important;
}
.introText{
    font-size: 22px !important;
}
.listPolicy {
   
    font-size: 18px;
    padding-top:15px;
   
}
.cookiesPolicy {
   
    padding: 5px 30px;
}
.timeSlotHead {
    padding: 35px 0px 30px;
}
.tSlotHeadLeft p:nth-of-type(1){
    font-size: 24px !important; 
}
.tSlotHeadLeft p:nth-of-type(2){
    font-size: 16px !important; 
}
.holidayCal {
   
    padding: 3px 20px !important;
    font-size: 14px !important;
}
.holidayList li {
   
    font-size: 15px !important;

   
}
.holidayList li button {
  
    width: 22px !important;
    height: 22px !important;
    font-size: 17px !important;
}
.bookTimeText{
    font-size: 24px !important;
    margin-bottom:15px !important;
}
.availSlotText{
    font-size: 17px !important;
}
.addSessBtn {
    width: 140px;
    height: 35px;
    font-size: 15px;
 
}
.tutorTable {
    margin-bottom: 70px;
  
}
.input-assumpte + label::before {
   
    min-width: 22px !important;
    min-height: 22px !important;
    max-width: 22px !important;
    max-height: 22px !important;
    content: "✓";
    margin-right: 10px !important;
}
.paidUserHeadText {
    font-size:33px !important;
}
.quizResSecH3{
    font-size: 24px !important;
}
.quizRHeadText{
    font-size: 33px !important
}
.tutorialVidText{
    font-size:33px !important;
}
.forH3Text{
    font-size: 24px !important
}
.for72Font{
    font-size: 65px !important;
}
.subpackgMain {
    gap: 2%;
}
.subpackgMain .subpackgBox{
    margin-bottom:15px;
}
.subPlanSubCont{
    padding-top:20px;
}
.respHeader{
    display:none !important;
}
.sideBarMain{
    display: block !important;
}
.sideBarMain .appendHeader{}

div#claimyour\ free\ tutoring\ for\ 1\ hour {
    font-size: 13px !important;
}
.logoImg {
    max-width: 100%;
    padding: 0 5px;
}
div#freetrial {
    font-size: 14px !important;  
}
.signUISec {
    min-width: 160px;
    display: flex;
}
.css-12ii80c-placeholder {
    
    font-size: 14px !important; 
}
.subTabsMain {
    padding: 45px 140px;
}
.caseSummarSubMain {
    padding-top: 30px;
}
.checkListNowSec {
  
    padding: 20px;
   
    margin-bottom: 30px;
}
.flex.relative.caseSumGreenMain {
    margin-bottom: 20px;
}
.materialSlider li.react-multi-carousel-item.react-multi-carousel-item--active.z-10 {
    
    width: 40% !important;
}

}
@media (max-width: 991px) {
     /* .tickBulletSec .tickBulletSec-infoicon .tooltipSSec .infoSubBox{
        position: absolute;
   right: unset !important;
   left: -40px !important;
    } */
    .memberShipTable td {
        font-size: 15px;
    }
    .subPSubText {
        font-size: 26px !important;
    }
    .upgrateBtnBox div {
        font-size: 15px;
    }
    .subPlanSubCont {
        margin-top: 10px;
    }
    .text-input {
        height: 42px !important;
        font-size: 15px;
    }
    .form-control {
        height: 42px !important;
        font-size: 15px;
    }
    .queryBtn {
        max-width: 35% !important;
        font-size: 18px !important;

        min-height: 40px;
    }
    label {
        font-size: 15px !important;
    }
    .alterText {
        font-size: 16px;
    }
   
    
    .efUpdateBtnSec {
    height: 45px;
    
}
.efUpdateBtnSec div {
   
    font-size: 15px;
}
.efUpdateBtnSec button{
      font-size: 15px;
}
form.resetPswrdForm .text-input{
    padding-right: 60px !important;
    font-size: 14px;
    
}
form.resetPswrdForm .text-input-container span{
    top:10px;
    
}
.userContSec .dayMonthSec p {
    font-size: 18px;
}
.userContSec .appointTitle{
    font-size: 16px !important;
}
.userContSec .freeGroupSec p {
    font-size: 15px;
}
label.pnpModel {
    font-size: 17px !important;
}
.bookNowSec button{

}
.bookNowSec div{
    font-size: 16px;
}
.tutServHeading p {
    font-size: 20px;
}
.pckgContainer{
    max-width:100% !important;
}
.timeText {
    font-size: 17px;
    /* padding-right: 30px; */
   
}
.bookBtn {
   
    width: 120px;
    height: 45px;

}
.selectTutrSec .css-1jxare7-control {
    min-height: auto;
    height: 47px;
    font-size: 14px;
}
.schduelSec p{
    font-size: 15px;
}
.schduelSec p:nth-of-type(1){
    font-size: 18px;
    margin-bottom: 5px;
}
.text-h5 {
    font-size: 17px !important;
}
.confrmBtn{
    font-size: 16px;
}
.invoiceHead{
    flex-direction: column !important;
}
.invoiceMain div{
    word-break: break-all;
}
.buttonClass {
    
    font-size: 15px !important;
}
.subpackgMain{
    gap:2%;
}
.priceText {
    font-size: 37px;
}
p.tutSerDesc div p span {
    font-size: 17px !important;
}
.BoostPWrapper {
    padding: 0 40px !important;
}
.materialSlider .flip-card {
 
    width: 250px;
  
}
.react-multiple-carousel__arrow {
   
    min-width: 38px !important;
    min-height: 38px !important;
   
}
.css-1jxare7-control{
    height: 44px !important;
}
.loginHeadText {
    font-size: 28px;
}
.socialBtn{
    height: 45px !important;
}
.caseHeadText{
    font-size: 30px;
}
.letterPagMain div{
    font-size: 16px;
}
.aftPagMainSec p {
    font-size: 18px !important;
}
.buttonClass p{
    font-size: 16px !important;
}
h1.entry-title{
    font-size: 27px !important;
}
.readCBtn{
    font-size: 16px !important;
}
.entry-content ul li {
    font-size: 14px;
}
.entry-content ul li span {
    font-size: 14px !important;
}
/* .entry-content span{
    font-size: 16px !important;
} */
/* .entry-content p{
    font-size: 16px !important;
} */
.goBackText{
    font-size: 23px !important;
}
.entry-content span{
    font-size: 10pt;
}
.essayBox p{
   font-size: 16px;
}
input{
    font-size: 14px !important;
}
div#summary h1 {
    font-size: 25px !important;
}
div#summary span  {
    font-size: 18px !important;
}
div#summary span span {
    font-size: 17px !important;
}
.policyPara {
    font-size: 16px;
}
.tipsHeadText h2 {
    font-size: 26px;
}
.portLRight h2 {
    font-size: 23px;
  
}
.portLRight p {
    font-size: 15px;
   
}
.portRight .prHeading {
    font-size: 32px;
   
}
.portRight {
    padding: 35px 20px 50px;
}
.portRight p {
    font-size: 16px;
   
}
.portLeft svg {
    width: 3.5rem;
    height: 4.5rem;
}
.learnMoreBox a {
    font-size: 18px;
}
.cMatrialText{
    font-size: 28px !important;
}
.timeline ul li.in-view div {
   
    font-size: 16px;
}
.timeline ul li {
    
   
    padding-top: 24px;
 
}
.valueText{
    font-size:28px !important;
}
.valBox h1 {
    font-size: 17px;
   
}
.valBox p {
   
    font-size: 14px;
}
.digiH1Text{
    font-size: 24px !important;
}
.digBoxRight h2 {
    font-size: 24px;
}
.meetTeamtext{
    font-size: 28px !important;
}
.teamInfoSec h3 {
    font-size: 19px;
  
    margin-bottom: 10px;
}
.teamInfoSec {
    /* padding-top: 2px !important; */
}
.teamInfoSec h3 span {
    font-size: 16px;
  
}
.teamInfoSec {
    padding: 10px 5px !important;
}
.teamInfoSec p {
    font-size: 15px;
}
.partBoxOne img {
    width: 340px;
    margin-bottom: 20px;
   
}
.pTitleText{
    font-size: 25px !important;
}
.partBoxP {
    font-size: 16px;
    margin-bottom: 15px;
}
.partBoxLink {
    font-size: 16px;

}
.partBoxOne {
    padding: 12px 22px;

    margin-bottom: 28px;
}
.ourPrtnerText{
    font-size: 25px !important;
}
section#introduction p {
    margin-top: 10px !important;
}

.termCondText{
    font-size: 28px !important;
}
.privacyTableContent p {
  
    font-size: 16px;
    padding: 8px;
}
.termCondOlSec ol{
    font-size:17px !important;
}
section#introduction p {
    margin-top: 8px !important;
}
.introText{
    font-size: 20px !important;
}
.termFontSet {
    font-size: 16px !important;
}
.listPolicy {
   
    font-size: 16px;
    
   
}
.cookiesPolicy {
   
    padding: 5px 20px;
    margin-top:5px !important;
}
.quizHeadText{
    font-size: 32px !important;
}
.input-assumpte + label::before {
   
    min-width: 20px !important;
    min-height: 20px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    content: "✓";

}
.paidUserHeadText {
    font-size:30px !important;
}
.quizResSecH3{
    font-size: 22px !important;
}
.quizRHeadText{
    font-size: 30px !important
}
.fontWeightQuiz {
    font-size: 20px !important;
}
.tutorialVidText{
    font-size:30px !important;
}
.forH3Text{
    font-size: 22px !important
}
.flashCardBtnSec {
    margin-top: 120px !important;
}
.allSpecLText{
    margin: 20px 0px !important;
    line-height: 35px;
}
.for72Font{
    font-size: 61px !important;
}
.hidden-ribbon{
    display: none;
}
.checkmark {
  
    top: 0px;
 
    height: 22px;
    width: 22px;
  
}
.selected-slots{
    font-size: 14px !important;
}
.cardTitleSec {
    height: 70px;
}

.searchIcon {
    width: 35px;
        /* max-height:45px !important; */
    

    
}
.searchIcon img{
    max-width: 17px;
}
div#claimyour\ free\ tutoring\ for\ 1\ hour {
    font-size: 12px !important;
}
.adminSearchWrap div#react-select-2-placeholder{
    font-size: 11px !important;
}
.searchWrap2 div#react-select-2-placeholder{
    font-size: 11px !important;
}
/* .adminSearchWrap{
    display:none !important;
} */
.adminSearchWrap {
    min-width: 350px ;
    display: none !important;
}
.searchWrap2{
    min-width: 350px ;
    display: none;
}
.searchWrapTwo{
    display:block !important;
    width:80%;
}
.secSearch{
display:block !important;
/* padding-bottom: 5px; */
}
.adminWrapSec{
    display: none;
}
.logoImg {
    max-width: 90%;
    min-width: 50px;
    
}
.secSearch .css-12ii80c-placeholder {
  
    font-size: 12px !important;
}
div#freetrial {
    font-size: 13px !important;  
}
.secSearch{
    /* padding-bottom: 10px; */
}
.sHBtnSec {
    flex-direction: row;
    align-items: center;
    flex: 1 ;
}
.searchWrap.adminSearchWrap.adminWrapSec {
    display: none !important;
}
.searchWrap.searchWrap2.adminWrapSec {
    display: none !important;
}
.subTabsMain {
    padding: 40px 70px;
}
.tickBulletSec svg {
    height: 35px;
    width: 35px;

}
.tickBulletSec1 svg {
    height: 35px;
    width: 35px;

}
.tickBulletSec3 svg {
    height: 35px;
    width: 35px;

}
.checkListNowSec {
    padding: 30px 15px;
    
    font-size: 14px;
}
p#content p span {
    font-size: 14px !important;
}
.greenCardsec div:nth-of-type(1){
    padding: 20px 20px;
}
.caseSumGreenMain{
    padding: 0 !important;
}
.caseSumGreenMain img{
    margin-right:8px;
}
.testiFooterText {
    font-size: 18px;
    
}
.notesSecMain{
    flex-wrap: wrap;

}
.greenCardsec {
    
    margin-left: auto;
    width: 50% !important;
}
.notesSecMain .testiSliderMain .react-multi-carousel-list{
    padding: 0 !important;
}
.notesSecMain .react-multiple-carousel__arrow {
    min-width: 26px !important;
    min-height: 25px !important;
}
.notesSecMain .onBlurSec {
    width: 100%;
}
}
@media (max-width: 580px) {
    .tickBulletSec .toolTipMain .tooltipSSec .infoSubBox{
        width: 50%;
        min-width: 280px;
    }
    .tickBulletSec1 .toolTipMain .tooltipSSec .infoSubBox{
        width: 50%;
        min-width: 280px;
    }
    .tickBulletSec3 .toolTipMain .tooltipSSec .infoSubBox{
        width: 50%;
        min-width: 280px;
    }
    /* .tickBulletSec .tickBulletSec-infoicon .tooltipSSec .infoSubBox{
        position: absolute;
   right: unset !important;
   left: 80px !important;
    } */
}
@media (max-width: 970px) {
    /* .tickBulletSec .tickBulletSec-infoicon .tooltipSSec .infoSubBox{
        position: absolute;
   right: unset !important;
   left: 20px !important;
    } */
    .tickBulletSec1 .toolTipMain .tooltipSSec .infoSubBox{
        width: 50%;
        min-width: 280px;
    }
}
@media (max-width: 767px) {
    /* .tickBulletSec .tickBulletSec-infoicon .tooltipSSec .infoSubBox{
        position: absolute;
   right: unset !important;
   left: 20px !important;
    } */
    .tickBulletSec1 .toolTipMain .tooltipSSec .infoSubBox{
        width: 50%;
        min-width: 280px;
    }
    .addSessBtn {
        width: 120px !important;
        font-size: 11px !important;
     
    }
    .nav-item {
        padding-right: 10px !important;
    }
    .searchIcon {
        width: 40px;
    }
    .searchIcon img {
        width: 20px;
    }
    .memberShipTable th {
        font-size: 15px;
    }
    .memberShipTable td {
        font-size: 14px;
    }
    .css-12ii80c-placeholder {
        font-size: 16px !important;
    }
    .subPSubText {
        font-size: 23px !important;
    }
    .subPlanCont p {
        font-size: 15px;
    }
    .subPlanSubCont p {
        font-size: 15px;
    }
    .upgrateBtnBox button {
        font-size: 15px;
    }
    .upgrateBtnBox div {
        font-size: 14px;
    }
    .subPlanSubCont {
        margin-top: 8px;
    }
    .applyInputSec {
        height: auto;
    }
    .applyInputSec div {
        height: 40px;
        font-size: 14px;
    }
    .instrSec p {
        font-size: 14px;
    }
    .text-input {
        height: 38px !important;
        font-size: 14px;
    }
    .form-control {
        height: 38px !important;
        font-size: 14px;
    }
    textarea {
        font-size: 14px;
    }
    label {
        font-size: 14px !important;
    }
    .alterText {
        font-size: 15px;
    }
     .efUpdateBtnSec {
    height: 40px;
    
}
.efUpdateBtnSec div {
   
    font-size: 14px;
}
.efUpdateBtnSec button{
      font-size: 15px;
}
form.resetPswrdForm .text-input-container span {
    top: 8px;
    width: 20px;
}
form.resetPswrdForm .text-input {
    padding-right: 60px !important;
    font-size: 13px;
}
.userContSec .dayMonthSec p {
    font-size: 16px;
}
.userContSec .appointTitle{
    font-size: 15px !important;
}
.userContSec .freeGroupSec p {
    font-size: 14px;
}
.tutorTabs button {
    font-size: 14px !important;
}
label.pnpModel {
    font-size: 16px !important;
}
.bookNowSec div{
    font-size: 15px;
        margin-top: -20px;
    margin-bottom: -20px;
}
.tutServHeading p {
    font-size: 18px;
}
.tutSerDesc div{
    font-size: 14px;
}
.timeText {
    font-size: 16px;
    /* padding-right: 15px; */
   
}
.text-left {
    
    font-size: 14px;
}
.pckgPriceText{
    display: block;
    margin: 0 !important;
    text-align: center;
}
.packBtmSec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 7px;
}
.packBtmSec h2{
    font-size: 14px; 
}
.forBoxBorder{
    width: 48.5% !important;
}
.selectTutrSec .css-1jxare7-control{
    min-height: auto;
    height: 40px;
}
.bookBtn {
   
    width: 100px;
    height: 40px;

}
.schduelSec p{
    font-size: 14px;
}
.schduelSec p:nth-of-type(1){
    font-size: 18px;
}
.text-h5 {
    font-size: 16px !important;
}
.confrmBtn{
    font-size: 15px;
}
.addInfoText {
    width: 100% !important;
        font-size: 15px;
}
.invoiceMain div{
     font-size: 15px;
}
.buttonClass {
    
    font-size: 14px !important;
}
.priceText {
    font-size: 33px;
    margin:0 2px;
}
.subBultText{
    font-size: 15px;
}
.text-body-large {
    font-size: 16px;
}
p.tutSerDesc div p span {
    font-size: 15px !important;
}
.BoostPWrapper {
    padding: 0 30px !important;
}
.materialSlider .flip-card {
    width: 85%;
    margin: 0 auto;
}
.react-multiple-carousel__arrow {
   
    min-width: 34px !important;
    min-height: 34px !important;
   
}
.flip-card-back.p-4 {
    padding: 10px !important;
 
}
.materialSlider .flip-card-back :nth-of-type(1){
    font-size: 16px !important;

}
.css-1jxare7-control{
    height: 40px !important;
}
.pckgContainer{
    padding: 0 !important;
}
.loginBottomText{
    font-size: 14px;
}
.loginAlertMain p{
    font-size: 14px;
}
.loginAlertMain p a{
    font-size: 16px;
}
.loginHeadText {
    font-size: 25px;
}
.socialBtn{
    height: 41px !important;
}
.socialBtn svg{
    /* max-width:15px; */
    /* height:15px; */
}
.socialBtn span{
    font-size: 14px;
}
.forgetText{
    font-size:14px;
}
.socialBtn {
   
    font-size: 14px !important;

}
.loginInputMain p{
    font-size: 14px;
}
.eyeIconSec{
    top: 8px;
}
.eyeIconSec img{
width:20px;
}
button.socialBtn.metro {
    font-size: 14px !important;
}
.googleAuthBox .socialBtn div {
    background: none !important;
}
.caseHeadText{
    font-size: 25px;
}
.letterPagMain div{
    font-size: 14px;
}
.aftPagMainSec p {
    font-size: 16px !important;
}
h1.entry-title{
    font-size: 24px !important;
}
.readCBtn{
    font-size: 14px !important;
}
.entry-content{
    font-size: 13px !important;
}
.entry-content h1 span {
    font-size: 21px;
}
.entry-content h1  {
    margin-bottom: 10px !important;
}
.entry-content ul li {
    font-size: 13px;
}
.entry-content ul li span {
    font-size: 13px !important;
}
/* .entry-content span{
    font-size: 15px !important;
} */
/* .entry-content p{
    font-size: 15px !important;
} */
.goBackText{
    font-size: 20px !important;
}
.entry-content span{
    font-size: 10pt !important;
}
.dangerousSummary p img {
    width: 185px;
      margin: 5px auto 15px;
}
/* .prevNextBtnBox {
    height: 35px;
} */
.essayBox p{
   font-size: 14px;
}
input{
    font-size: 13px !important;
}
div#summary h1 {
    font-size: 22px !important;
}
.notesReadSec {
    margin-bottom: 20px !important;
}
div#summary span  {
    font-size: 16px !important;
}
div#summary span span {
    font-size: 15px !important;
}
.policyPara {
    font-size: 14px;
}
.tipsHeadText h2 {
    font-size: 24px;
}
.tipsHeadText {
    height: 35px;
    margin-bottom: 5px;
    
}
.aboutUsCont{
    margin: 0 !important;
}
.ourStorytext{
     font-size: 24px;
     margin-bottom: -45px !important;

}
p.aboutUsCont{
    font-size: 18px !important;
}
.portLRight h2 {
    font-size: 21px;
  
}
.portLRight p {
    font-size: 14px;
   
}
.portRight .prHeading {
    font-size: 28px;
   
}
.portRight p {
    font-size: 14px;
   
}
.learnMoreBox a {
    font-size: 16px;
}
.learnMoreBox hr {
    border-top-width: 2px;
}
.portLeft {
    padding: 60px 20px 20px;
}
.cMatrialText{
    font-size: 25px !important;
}
.timeline ul li.in-view div {
   
    font-size: 14px;
}
.timeline ul li {
    
   
    padding-top: 20px;
 
}
.timeline ul li:nth-child(even) div {
    left: -280px;
}
.timeline ul {
   
    padding: 15px 0;
}
.timeline ul li {
   
    width: 4px;
  
}
.timeline ul li::after {
    content: "";
    width: 25px;
    height: 25px;
    font-size: 12px;
}
.valueText{
    font-size:26px !important;
}
.valBox h1 {
    font-size: 15px;
   
}
.valBox p {
   
    font-size: 13px;
}
.digiH1Text{
    font-size: 22px !important;
        margin: 0 !important;
    margin-top: -100px !important;

}
.digBoxRight h2 {
    font-size: 22px;
}
.meetTeamtext{
    font-size: 26px !important;
}
.teamInfoSec h3 {
    font-size: 17px;
  
    margin-bottom: 7px;
}
.teamInfoSec h3 span {
    font-size: 14px;
  
}
.teamInfoSec p {
    font-size: 14px;
}
/* Optional Tailwind-inspired styles */
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
  }
  
header.meetTeamHeader {
    flex-direction: column !important;
}
.partBoxOne img {
    width: 290px;
    margin-bottom: 17px;
   
}
.pTitleText{
    font-size: 23px !important;
}
.partBoxP {
    font-size: 14px;
    margin-bottom: 12px;
}
.partBoxLink {
    font-size: 14px;

}
.partBoxOne {
    padding: 10px 20px;
    margin-bottom: 25px;
}
.ourPrtnerText{
    font-size: 23px !important;
}




.termCondText{
    font-size: 26px !important;
}
.privacyTableContent p {
  
    font-size: 14px;
    padding: 7px;
}
.termCondOlSec ol{
    font-size:15px !important;
}
section#introduction p {
    margin-top: 7px !important;
}
.introText{
    font-size: 18px !important;
    margin-top: 10px !important;
    padding: 0 !important;
    
}
.paraPointSec{
    padding: 0 !important;
}
.termFontSet {
    font-size: 14px !important;
}
.listPolicy {
   
    font-size: 14px;
   
}
.cookiesPolicy {
   
    /* padding: 5px 10px; */
}
.quizHeadText{
    font-size: 29px !important;
}
.quizIndexImgSec div span img{
    width: 70%;
    margin: 0 auto;
}
.input-assumpte + label::before {
   
    min-width: 18px !important;
    min-height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
    content: "✓";

}
.paidUserHeadText {
    font-size:27px !important;
}
.quizResSecH3{
    font-size: 20px !important;
}
.quizRHeadText{
    font-size: 27px !important
}
.fontWeightQuiz {
    font-size: 18px !important;
}
.tutorialVidText{
    font-size:27px !important;
}
.forH3Text{
    font-size: 20px !important
}
.flashCardBtnSec{
    margin-top:30px !important;
}
.flashCardBox {
   
    width: 480px !important;
}
.sHBtnSec{
    /* flex-wrap: wrap; */
}
.homeSignUpBtn {
    float:right;
    margin: 10px 0px;
    align-self: center;
}
.for72Font{
    font-size: 57px !important;
}
.checkmark {
  
    top: 0px;
 
    height: 19px;
    width: 19px;
  
}
.selected-slots{
    font-size: 13px !important;
}
div#freetrial {
    font-size: 12px !important;  
}
.smallHeader {
    /* flex-direction: column; */
   justify-content: space-between;
    align-items: center;
}
/* .sHBtnSec{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex:1;
} */
img.logoOne {
    min-width: 35px;
    width: 45px;
    margin-right: 15px;
}
.adminSearchWrap {
    /* min-width: 220px !important; */
    /* width: fit-content; */
}
.searchWrap2 {
    min-width: 220px !important;
    width: fit-content;
}
.signUISec{
    align-items: center;
}
.subTabsMain {
    padding: 40px 30px;
}
.tickBulletSec svg {
    height: 30px;
    width: 30px;

}
.tickBulletSec1 svg {
    height: 30px;
    width: 30px;

}
.tickBulletSec3 svg {
    height: 30px;
    width: 30px;

}
/* .subBoxMain .tickBulletSec .tooltipSSec .infoSubBox {
   
    width: 460px !important;
    min-width:auto;
    margin: 0 auto;
   
} */
.onBlurSec {
    width:100% !important;
}
.testiFooterText {
    font-size: 16px;
    
}
.checkListNowSec {
    margin-top: 30px;
   
}
.notesSecMain .greenCardsec {
    margin-left: 0;
    width: 100% !important;
}
}

@media (max-width: 390px) {
    .adminSearchWrap{
        /* max-width: 210px !important;
         */
         /* width: max-content; */
    }
    .searchWrap2{
        max-width: 210px !important;
        
         width: fit-content;
    }
}
@media (max-width: 360px) {
    .adminSearchWrap{
        /* max-width: 180px !important; */
        /* width: 200px; */
    }
}
@media (max-width: 575px) {
    .searchIcon img {
    width: 16px;
}
    .logoSec img {
        width: 50px;
        margin-top: -5px;
    }
    .memberShipTable th {
        font-size: 14px;
    }
    .memberShipTable td {
        font-size: 13px;
    }

    .css-12ii80c-placeholder {
        font-size: 13px !important;
    }
    .subPSubText {
        font-size: 20px !important;
    }
    .subPlanCont p {
        font-size: 13px;
    }
    .subPlanSubCont p {
        font-size: 13px;
    }
    .subPlanSubCont {
        margin-top: 0px;
    }
    .bg-subscription-upper {
        padding-top: 12px;
        background: none !important;
    }
    .bg-subscription-upper p {
        font-size: 16px !important;
    }
    .subPlanCont p {
        font-size: 12px !important;
    }
    .instrSec p {
        font-size: 13px;
    }
    .instrSec {
        margin-bottom: 12px;
    }
    .applyInputSec input {
        font-size: 13px;
    }
    div#headlessui-menu-items-3 {
        right: -25px;
        font-size: 13px;
    }
    .text-input {
        height: 35px !important;
        font-size: 13px;
    }
    .form-control {
        height: 35px !important;
        font-size: 13px;
    }
    textarea {
        font-size: 13px;
    }
    label {
        font-size: 13px !important;
    }
    .alterText {
        font-size: 14px;
    }
     .efUpdateBtnSec {
    height: 38px;
    
}
.efUpdateBtnSec div {
   
    font-size: 13px;
}
.efUpdateBtnSec button{
      font-size: 15px;
}
form.resetPswrdForm .text-input {
    
    font-size: 12px;
}
.userContSec .dayMonthSec p {
    font-size: 15px;
}
.userContSec .appointTitle{
    font-size: 14px !important;
}
.userContSec .freeGroupSec p {
    font-size: 13px;
}
.tutorTabs button {
        font-size: 13px !important;
    margin-top: -10px !important;
    height: 35px !important;
    margin-bottom: 2px;
}
.tutorTabs ul#myTab {
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
}
.tutorTabs ul#myTab li {
    margin-right:20px;
    margin-bottom: 5px;
}
.scheduleBtn{
    right:0;
    padding: 5px;
}
label.pnpModel {
    font-size: 15px !important;
    min-width:80px;
    width: 120px;
}
.input-assumpte + label::before{
        
    min-width: 18px !important;
    min-height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
    content: "✓";
 
}

.checkBoxGrades + label::before{
    min-width: 18px !important;
    min-height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
    content: "✓";
}
.bookNowSec div{
    font-size: 14px;
}
.tutServHeading p {
    font-size: 16px;
}
.tutSerDesc div{
    font-size: 13px;
}
.timeText {
    font-size: 15px;
    /* padding-right: 10px; */
   
}
.forBoxBorder{
    width: 88% !important;
    margin: 0 0 20px 0 !important;
}
.selectTutrSec .css-1jxare7-control{
    min-height: auto;
    height: 35px;
    font-size: 12px;
}
.bookBtn {
   
    width: 90px;
    height: 35px;

}
.schduelSec p{
    font-size: 13px;
}
.text-h5 {
    font-size: 15px !important;
}
.confrmBtn{
    font-size: 14px;
}
.schduelSec p:nth-of-type(1) {
    font-size: 16px;
}
.addInfoText{
   
        font-size: 13px;
}
.invoiceMain div{
     font-size: 13px;
}
.buttonClass {
    
    font-size: 13px !important;
}
.priceText {
    font-size: 29px;
}
.subBultText{
    font-size: 14px;
}
.text-body-large {
    font-size: 13px;
}
.applyInputSec div {
    height: 35px;
    font-size: 13px;
}
p.tutSerDesc div p span {
    font-size: 14px !important;
}
.BoostPWrapper {
    padding: 0 15px 0 20px !important;
}
.materialSlider .flip-card {
    /* width: 70%; */
    margin: 0 auto;
    position:relative;
}
.react-multiple-carousel__arrow {
   
    min-width: 30px !important;
    min-height: 30px !important;
   
}
.materialSlider .react-multiple-carousel__arrow {
   
    min-width: 20px !important;
    min-height: 20px !important;
   
}
.materialSlider .react-multiple-carousel__arrow--right {
    /* right: calc(1% + 1px); */
}
.materialSlider .react-multiple-carousel__arrow--left {
    /* left: calc(1% + 1px); */
}
.materialSlider .react-multiple-carousel__arrow::before {
    font-size: 13px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}
.materialSlider .flip-card-back :nth-of-type(1){
    font-size: 15px !important;

}
.materialSlider li.react-multi-carousel-item.react-multi-carousel-item--active.z-10 {
    
    /* width: 80% !important; */
    height: 100%;
    min-height: 500px !important;
   /* margin: 0 20px!important; */
   margin: 0!important;
    /* max-width: 550px !important; */
}
.materialSlider .lazy-load-image-background img {
    width: 100% !important;
    min-height: 240px;
    text-align: center;
    margin: 0 auto;
}

.css-1jxare7-control{
    height: 37px !important;
    font-size: 12px;
}
.loginBottomText{
    font-size: 12px;
}
.loginAlertMain p{
    font-size: 14px;
}
.loginAlertMain p a{
    font-size: 15px;
}
.loginHeadText {
    font-size: 21px;
}
.socialBtn{
    height: 37px !important;
}
.forgetText{
    font-size:13px;
}
.socialBtn span{
    font-size: 13px;
}
.socialBtn {
   
    font-size: 13px !important;

}
button.socialBtn.metro {
    font-size: 13px !important;
}
.loginInputMain p{
    font-size: 13px;
}
.eyeIconSec{
    top: 9px;
}
.eyeIconSec img{
width:17px;
}
.caseHeadText{
    font-size:19px;
}
.breadCrumSubMain button {
    font-size: 12px;
}
.breadCrumSubMain p {
    font-size: 12px;
}
.breadCrumSubMain img {
    margin: 0 8px !important;
}
.letterPagMain div{
    font-size: 12px;
}
.aftPagMainSec p {
    font-size: 15px !important;
}
h1.entry-title{
    font-size: 20px !important;
}
.readCBtn{
    font-size: 12px !important;
}
.entry-content{
    font-size: 12px !important;
}
.entry-content h1 span {
    font-size: 18px;
}
.buttonClass p{
    font-size: 12px !important;
}
p.noResultText {
    font-size: 14px;

}
.entry-content ul li {
    font-size: 12px;

}
.entry-content ul li span {
    font-size: 12px !important;

}
/* .entry-content span{
    font-size: 13px !important;
} */
/* .entry-content p{
    font-size: 13px !important;
} */
.goBackText{
    font-size: 17px !important;
}
/* .entry-content span{
    font-size: 6pt;
} */
.dangerousSummary p img {
    width: 160px;
    /* margin-bottom: 10px; */
  
}
/* .prevNextBtnBox {
    height: 30px;
} */
.essayBox p{
   font-size: 13px;
}
input{
    font-size: 12px !important;
}
div#summary h1 {
    font-size: 20px !important;
}

div#summary span  {
    font-size: 14px !important;
}
div#summary span span {
    font-size: 13px !important;
}
.policyPara {
    font-size: 13px;
}
.tipsHeadText h2 {
    font-size: 22px;
}

.ourStorytext{
     font-size: 22px;
    margin-bottom: 15px !important;
}
.portLRight h2 {
    font-size: 18px;
  
}
.portLRight p {
    font-size: 12px;
   
}
.portRight .prHeading {
    font-size: 24px;
   
}
.portRight p {
    font-size: 12px;
   
}
.learnMoreBox a {
    font-size: 13px;
}
.learnMoreBox hr {
    border-top-width: 1px;
}
.portLeftSubSec{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.portLeft {
    padding: 60px 50px 20px;
    text-align: center;
}
.portRight {
    padding: 35px 40px 50px;
}
.cMatrialText{
    font-size: 21px !important;
}
.timeline ul li.in-view div {
   
    font-size: 12px;
}
.timeline ul li {
    
   
    padding-top: 15px;
 
}
.valueText{
    font-size:24px !important;
}
.valBox h1 {
    font-size: 14px;
   
}
.valBox p {
   
    font-size: 12px;
}
.digiH1Text{
    font-size: 20px !important;
}
.meetTeamtext{
    font-size: 24px !important;
}
.teamInfoSec h3 {
    font-size: 15px;
  
    margin-bottom: 4px;
}
.teamInfoSec h3 span {
    font-size: 12px;
  
}
.meetTeamHeader{
    flex-direction: column;
}
.teamInfoSec {
 
    width: 100% !important;
    text-align: center;
}
.teamInfoSec p {
    font-size: 13px;
}
.partBoxOne img {
    width: 220px;
    margin-bottom: 10px;
   
}
.pTitleText{
    font-size: 20px !important;
}
.partBoxP {
    font-size: 12px;
    margin-bottom: 10px;
}
.partBoxLink {
    font-size: 12px;

}
.partBoxOne {
    padding: 10px 15px;

    margin-bottom: 20px;
}
.ourPrtnerText{
    font-size: 20px !important;
}
.termCondText{
    font-size: 24px !important;
}
.privacyTableContent p {
  
    font-size: 13px;
    padding: 8px;
}
.termCondOlSec ol{
    font-size:13px !important;
}
section#introduction p {
    margin-top: 5px !important;
}
.introText{
    font-size: 16px !important;
}
.termFontSet {
    font-size: 12px !important;
}
.listPolicy {
   
    font-size: 13px;
   
}
.quizHeadText{
    font-size: 25px !important;
}
.quizResSecH3{
    font-size: 18px !important;
}
.quizRHeadText{
    font-size: 24px !important
}
.fontWeightQuiz {
    font-size: 16px !important;
}
.tutorialVidText{
    font-size:24px !important;
}
.forH3Text{
    font-size: 18px !important
}
.flashCardBox {
   
    width: 310px !important;
}
.for72Font{
    font-size: 53px !important;
}
.checkmark {
  
    top: 0px;
 
    height: 17px;
    width: 17px;
  
}
.selected-slots{
    font-size: 12px !important;
}
.selectBokMain {
   
    flex-direction: column;
}
.tooltipSSec {
    width: 100% !important;
    min-width: 100% !important;
    /* left: -80px; */
    z-index: 9999;
}
.cardTitleSec {
    height: 60px;
    padding-top:20px;
}
.headDropItemSec{
    width:200px !important;
}
.adminSearchWrap {
    min-width: 248px;
    z-index:1;
}
.searchWrap2 {
    min-width: 248px;
    z-index:1;
}
.searchIcon{
    width:35px;
}
.dropArrowSec {
    width: 15px;
    margin: 0 !important;
    padding: 0;
}
div#claimyour\ free\ tutoring\ for\ 1\ hour {
    font-size: 10px !important;
}
.logoImg {
    max-width: 83%;
    min-width: 48px;
}
.smallHeader {
    /* flex-direction: column; */
    align-items: start;
}
.sHBtnSec {
    
    flex-direction: column;
}
.subTabsMain {
    padding: 35px 0px;
}
.subpackgMain {
    grid-gap: 2%;
    gap: 2%;
    flex-wrap: wrap;
}
.subBoxMain {
    width: 100% !important;
}
.tickBulletSec svg {
    height: 25px;
    width: 25px;

}
.tickBulletSec1 svg {
    height: 25px;
    width: 25px;

}
.tickBulletSec3 svg {
    height: 25px;
    width: 25px;

}
.subpackgBox {

    padding-bottom: 60px !important;
    width: 100% !important;
    min-height: 180vh;
    padding-left:10px;
    padding-right:10px;
  
}
.subBoxMain .tickBulletSec .tooltipSSec .infoSubBox {
   
    width: 290px !important;
    min-width:auto;
    margin: 0 auto;
    z-index: 999999 !important;
    position: absolute;
    right: -52px !important;
    display: block;
   
}
.FreeTrailBtnSec .toolTipMain {
    
    left: -20px;
    z-index: 9 !important;
}
.FreeTrailBtnSec1 .toolTipMain {
    
    left: -20px;
    z-index: 9 !important;
}
.testiSliderMain {
    position: relative;
    padding: 0 !important;
}
.testiFooterText {
    font-size: 14px;
    
}
.testiSliderMain .slide-content {
    font-size: 14px;
    /* padding: 0 8px !important; */
}
.testiSliderMain .react-multi-carousel-list {

    padding: 0 !important;
}
.testiSLiderWrapper .react-multiple-carousel__arrow--left {
    /* left: calc(1.5% + 1px); */

    /* right: calc(15.5% + 10px); */
    /* top: 0; */
}
.testiSLiderWrapper .react-multiple-carousel__arrow--right {
    /* right: calc(1.5% + 1px) !important; */
    /* right: calc(1.5% + 0px);
    top: 0;
    left: unset; */
}
.pdfBoxOne{
    padding: 0 0 0 15px !important;
}
.right-side {
    
    padding: 10px 0px !important;
  
}
.show-btn {
    
    width: 25px;
    height: 25px;
    left: 0px;
    z-index: 99999999;
}
}
@media (max-width:601px){
    .timeline ul li:nth-child(even) div {
    left: 45px;
}
}
@media(max-width:1261px){
    .tab-set{
  left: 20px !important;
}
}

@media(max-width:767px){
    .tickBulletSec .toolTipMain .tooltipSSec{
        right: 0px !important;
    }
    .subPlan .tickBulletSec .toolTipMain .tooltipSSec{
        right: 60px !important;
    }
    .upgrade-toolTipMain .infoSubBox{
     width: 380px !important;
     position: relative !important;
     left: -70% !important;
    }

}
@media(max-width:480px){
    /* .tickBulletSec .toolTipMain .tooltipSSec .infoSubBox {
        right: 100px !important;
    }} */}
    @media(max-width:360px){
        /* .tickBulletSec .toolTipMain .tooltipSSec .infoSubBox{
            right: -20px !important;
        }} */}
   @media(max-width:561px){
    .tickBulletSec .toolTipMain .tooltipSSec .infoSubBox{
        right: 20px !important;
    }
    .upgrade-toolTipMain .infoSubBox{
     min-width: 280px !important;
     position: relative !important;
     left: 30% !important;
    }
    /* .tickBulletSec .toolTipMain .tooltipSSec .infoSubBox{
        right: 280px !important;
    } */
    .subPlan .tickBulletSec .toolTipMain .tooltipSSec .infoSubBox{
        right: 40px !important;
    }
   }
   @media(max-width:540px){
    .upgrade-toolTipMain .infoSubBox{
     min-width: 280px !important;
     position: relative !important;
     left: 50% !important;
    }
    .toolTipMain {
        left: 35px !important;
    }
   }
   @media(max-width:1000px){
    .upgrade-toolTipMain .infoSubBox{
    width: 500px !important;
    right: -30% !important;
    }
   }
   @media(max-width:600px){
    .upgrade-toolTipMain .infoSubBox{
    width: 280px !important;
     position: relative !important;
     left: unset !important;
     right: 0% !important;
    }
   }
@media(max-width:623px){
    .setting{
   position: relative !important;
   left:-320px !important;
    }
}

@media(max-width:584px){
    .setting{
position: relative !important;
left:-223px !important;
    }
}
@media(max-width:521px){
    .setting{
position: unset !important;
    }
}
@media(max-width:600px){
    .para-hid{
        display:none;
    }
}
@media(max-width:767px){
    .sm-margin{
        margin-left: 0 !important;
    }
}
@media(max-width:468px){
    .box-set{
        max-width:100%;
        width:75%;
    }
    .flex-set{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media(max-width:767px){
    .bd-show{
        display: block !important;
    }
}
@media(max-width:767px){
    .Premium-tip{
        right:60px !important;
    }
}
@media(max-width:400px){
    .font-s{
        font-size: 10px !important;
    }
}
@media(max-width:396px){
    .trail-width{
        width:152px !important;
    }
}

@media(max-width:575px){
     .free-auto{
        left:unset;
    }
    .free-tool{
        /* left:-132px; */
    }
}

@media(max-width:822px){
    .toolTipMain .tooltipSSec.free-auto{
        right:325px !important;
    
      }
}
@media (max-width: 616px){
.toolTipMain .tooltipSSec.free-auto {
    right: 277px !important;
}
}

@media (max-width: 545px){
    .toolTipMain .tooltipSSec.free-auto {
        right: 211px !important;
    
    }
    }
    @media (max-width: 521px){
        .toolTipMain .tooltipSSec.free-auto {
            right: 65px !important;
        }
        }
    


@media(max-width:762px){
    .free-auto{
        right:302px !important;
      }
}
@media(max-width:575px){
    .cases{
        left:unset;
        /* right:326px !important; */
    }
    .cases .infoSubBox{
        width: 255px !important;
        right:-15px !important;
        
    }
}
@media(max-width:472px){
    .cases{
        /* right:220px !important; */
    }
}
/* pdf changes */
@media(max-width:768px){
    .rpv-core-button{
        padding: 1px !important;
    }
    .rpv-core-icon{
        width:12px;
    }
    .rpv-current-page-input{
        width:35px;
    }
    .rpv-toolbar-center{
        flex-grow: 0.3;
    }
    .rpv-zoom-popover-target{
        padding: 1px !important;
    }
    .rpv-toolbar-right{
        margin-left: 5px;
    }
}

@media(max-width:1023px){
    .tab-case-first{
        width:100% !important;
    }
}
@media(max-width:991px){
    .green-card-sec{
        width:100% !important;
    }
    .side-bar{
        width:40%;
    }
   
}
@media(max-width:700px){

    .side-bar{
    
        width:50%;
    }
}

@media(max-width:450px){
    .side-bar{
     width:85%;
    }
}
.sidenavStyle{
    background-color: #CC6828 !important;
}
/* 
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-{
    position: static !important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:nth-last-child(){
    position: absolute !important;
} */

nav.sidenavStyle.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv {
    position: fixed;
}
nav.sidenavStyle.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL{
    position: fixed;
}

.logoutItem{
    min-height: 100%;
    position: relative;
}

.sideBarProfileImage{
    margin: 13px 17px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
}

.sideBarProfileItem{
    bottom: 120px;
    position: absolute !important;
    width: 100%
}

.sideBarLogoutItem{
    bottom: 70px;
    position: absolute !important;
    width: 100%;
}



.mainDiv {
  width: 99%;
  /* background-color: bisque; */
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  background: #f7f7f7;
  gap: 20px;
  max-width: 99%;
  margin: 0 auto;
}
.boxOne {
  border: 2px solid #d76422;
  border-radius: 14px;
  padding: 4px;
  min-height: 120px;
  max-height: 100%;
  margin: 0 auto 25px;
  width: 85%;
  cursor: pointer;
}

.left-side {
  width: 25%;
  border-radius: 10%;
  text-align: center;
  background: #fff;
  border-radius: 14px;
  padding: 20px;
  height: 100vh !important;
  overflow: scroll;
  min-width: 340px;
}
.leftSideContent {
  padding: 20px 35px;
}

.right-side {
  width: 100%;
  border-radius: 10%;
  background: #fff;
  border-radius: 14px;
  padding: 0 !important;
  height: 100vh ! important;
  overflow: scroll;
}
.relative-div{
    position: relative;
    width: 100%;
}
.show-btn {
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #d76422;
    top: 50%;
    left: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index:9999;
}
.react-pdf__Page__textContent.textLayer {
  display: none !important;
  /* white-space: ; */
}

.sidenav---sidenav-toggle---1KRjR{
    float: right !important;
}

/* canvas.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
} */

 /* canvas.react-pdf__Page__canvas{
 width: -webkit-fill-available !important;
}   */

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

/* Loader  */
.loader-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure loader is above other content */
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #d76422;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.button-back{
  background-color: #d76422 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.button-container{
    position: sticky;
    top: 0;
    left: 0;
    min-height: 100px;
    background: #fff;
    z-index: 999999;

}






/* .button-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  height: 40px;
  margin-bottom: 10px;
  position: absolute;
  z-index: 9999999;
  width: 93%;
  margin-top: -20px;
  height: 100px;
  background-color: white;
} */


.button-container button {
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  height: 40px;
  width: 40px;
  margin-top: 25px;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.button-container button:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* .button-container button:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
} */

.button-container button:focus {
  outline: none;
}

.button-container button:hover img {
  transform: scale(1.2);
}

.button-container button svg {
  width: 30px; /* Set the width of the icons */
  height: 30px; /* Set the height of the icons */
  transition: all 0.3s ease;
}
.button-icon{
  height: '20px' !important;
  width: '20px' !important;
  color: #fff;
  margin-top: -4px !important;
}
.inputField{
  height: 35px;
  border: 1px solid #d76422;
  border-radius: 5px;
  margin-top: 25px;
  max-width: 250px;
    width: 100%;
    padding: 12px;

}
.page-number {
  font-size: 12px;
  font-weight: 500;
}
.max-w-1160 {
    max-width: 1160px;
}
@media only screen and (max-width: 1024px) {
    .team-points {
        max-height: 500px;
        overflow-y: auto;
    }
}
.boderClrbrown{
    border-color: #CC6828 !important;
    background: rgb(254, 252, 251) !important;
}
.flex{
    display: flex !important;
}
.text-19{
    font-size: 19px;
}
.summary-border{
    border-bottom: 1px solid #E2E2E2;
}
.rounded-img{
    border-radius: 50%;
}
.font-600{
    font-weight: 600;
}
.reactCalender{
    max-width: 492px;
    width: 100%;
    margin-bottom: 44px;
}
.reactCalender .react-calendar{
    width: 100%;
    border: none !important;
}
.reactCalender button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.reactCalender button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
    display: none !important;
}
.reactCalender button.react-calendar__navigation__label{
    width: 184px !important;
    flex-grow: unset !important;
    font-size: 21px;
}
.reactCalender button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.reactCalender button.react-calendar__navigation__arrow.react-calendar__navigation__next-button{
    font-size: 18px;
}
.reactCalender .react-calendar__navigation{
    justify-content: center;
    gap: 7px;
}
.reactCalender abbr {
    text-decoration: none !important;
    font-size: 16px !important;
    color: #1A1A1A9C;
    font-weight: 400 !important;
}
.reactCalender abbr[title]{
color: #1A1A1A !important;
}
.reactCalender .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: #CC682811 !important;
    border-radius: 50% !important;
    color: #CC6828 !important;
}
.reactCalender .react-calendar__month-view__weekdays__weekday{
    height: max-content !important;
    padding: 0 !important;
}
.reactCalender .react-calendar__month-view__days__day{
    height: 60px !important;

}
.reactCalender .react-calendar__tile--active,
.reactCalender .react-calendar__tile--now.react-calendar__tile--active{
    background: #CC6828 !important;
}
.reactCalender .react-calendar__tile--active abbr{
    color: #fff !important;
}

.reactTimezone{
    max-width: 326px;
    width: 100%;
    position: relative;
}
.reactTimezone .css-b62m3t-container {
    width: 100%;
}
.reactTimezone .css-13cymwt-control{
    border: none;
}
.reactTimezone span.css-1u9des2-indicatorSeparator{
    display: none;

}
.worldImg{
    position: absolute;
    bottom: 9px;
    left: 12px;
    z-index: 99;
}
.reactTimezone .css-1dimb5e-singleValue{
    padding-left: 29px;
}
/* Base styles for the labels */
.checkbox-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.25rem;
    font-size: 16px;
    text-align: center;
    border-radius: 0.75rem;
    cursor: pointer;
    color: #CC6828;
    border: 2px solid #CC6828;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
    margin-bottom: 20px;
}

/* Styles when the checkbox is checked */
.checkbox-input:checked + .checkbox-label {
    background-color: #434343;
    color: white;
    border: none;
}

 .reactCalender .react-calendar__tile--now {
    background: #fff !important;
}
  @media(max-width:1793px){
.summmary-sec{
    justify-content: space-between;

    div{
        margin-right: 0 !important;
    }
}
.summary-sec-2{
    flex-direction: column;
    flex-grow: unset;
    justify-content: unset;
  }
.media-p{
    margin-top: 15px;
}
  }

  @media (max-width: 991px) {

    .bookBtn2.bookBtn {
        width: 100% !important;
        height: auto !important;
        margin-bottom: auto !important;
        padding: 1rem;
    }
}
 .paymentDropZone{
    width: 100% !important;
    border: 1px solid #D8D8D8 !important;

 }
.react-modal-class2 button.react-responsive-modal-closeButton{
    display: none;
}
.react-responsive-modal-root{
    z-index: 99999999 !important;
}
 @media (max-width: 480px) {
 .react-responsive-modal-root{
    z-index: 99999999 !important;
 }
}

.nextButtonSlot {
    background: #cc6828;
    width: 300px;
    height: 64px;
    color: #fff;
    margin-bottom: 19px;
}