/* .react-responsive-modal-modal {
    border-radius: 25px !important;
    padding: 50px 64px !important;
    min-width: 60% !important;
} */

.parent-modal-class{
    width: 80% !important;
}
.parent-modal-class .react-responsive-modal-modal {
    border-radius: 25px;
    padding: 45px 55px;
    min-width: 80% !important;
    max-width: 100% !important;
  }

  .react-modal-class1{
    width: 80% !important;
    min-width: 80% !important;
  }


  .react-modal-class2{
    width: 50% !important;
    min-width: 50% !important;
  }
@media only screen and (min-width: 300px) and (max-width: 767px) {
    .react-responsive-modal-modal {
        margin: 1.2rem auto !important;
        padding: 62px 30px !important;
        min-width: 300px !important;
    }
}

.react-responsive-modal-closeButton {
    top: 20px !important;
    right: 20px !important;
}

.react-responsive-modal-overlay {
    background: linear-gradient(162deg, rgba(255, 196, 68, 0.2), rgba(243, 111, 86, 0.2)) !important;
    backdrop-filter: blur(20px);
}

#session-container::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
}

#session-container::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

#session-container::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #d8d8d8;
}

.checkbox .input-assumpte {
    display: none;
}

.input-assumpte+label::before {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border: 1px solid #8e8e8e;
    border-radius: 4px;
    /* display: inline-block; */
    color: #fff;
    transition-duration: 0.3s;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    content: "✓";
    margin-right: 12px;
}

.input-assumpte:checked+label::before {
    background-color: #cc6828;
    border: none;
}

@media (max-width: 1199px){
   
}