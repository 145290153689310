/* .searchWrap {
    width: 400px;
} */

.searchWrap .css-1jxare7-control {
    background: rgba(255, 250, 243, 1);
}

/*.css-26l3qy-menu {*/
/*    z-index: 999 !important;*/
/*}*/
