.rbc-date-cell.rbc-current a {
  background-color: #CC6828 !important;
  color: white;
  padding: 6px;
  border-radius: 50%;
  color: white;
}



.rbc-date-cell.rbc-current.rbc-now a {
  background-color: #CC6828 !important;
  padding: 6px;
  border-radius: 50%;
  color: white;
}

.rbc-day-bg, .rbc-day-bgb {
  border-left: 1px dashed #D8D8D8 !important;
  cursor: pointer;
}

.rbc-month-row, .rbc-month-row {
  border-top: 1px dashed #D8D8D8 !important;
}

.rbc-month-view {
  border: none !important;
}

.rbc-header, .rbc-header {
  border: none !important;
}

.rbc-month-row {
  justify-content: end;
}

.rbc-off-range-bg {
  background: #eeeeee !important;
  opacity: 0.3 !important;
}

.rbc-off-range {
  color: #B4B4B4 !important;
}

.rbc-date-cell>a {
  font-size: 12px !important;
}

.rbc-month-header {
  color: #8E8E8E;
  margin-bottom: 9px;
}

.rbc-header {
  font-size: 12px !important;
  font-weight: 400 !important;
}




[class="rbc-date-cell"] {
  color: #8E8E8E !important;
}

.rbc-today {
  background-color: #FFFAF3 !important;
}

.rbc-row {
  padding-bottom: 6px;
  padding-right: 8px;
}

.rbc-day-bg:first-child {
  border-left: none !important;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: linear-gradient(161.96deg, rgba(255, 196, 68, 0.2) 3.8%, rgba(243, 111, 86, 0.2) 231.69%) !important;
  z-index: 10;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}