.study-card-container {
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 4px 8px 20px rgba(2, 23, 54, 0.03);
  cursor: pointer;
}
.card-description-number {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1260px) {
  .title {
    font-size: 16px;
  }
  .desc {
    font-size: 12px;
  }
  .class {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 16px;
  }
  .desc {
    font-size: 12px;
  }
  .class {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .title {
    font-size: 18px;
  }
  .desc {
    font-size: 14px;
  }
  .class {
    font-size: 14px;
  }
}
