.infoCcontainer {
  margin-left: 36px;
}

.profile-info {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 106px;
  width: 100%;
}

.signup-info {
  /* height: 100vh; */
  
  width: 100%;
}

.profile-page-container {
  overflow-y: none;
}

.upgrade {
  cursor: pointer;
}
.color-CC6828{
  color:#CC6828 !important
}

.image-contanainer {
  border: 8px solid #fff;
  border-radius: 50%;
}
.tickBulletSec .toolTipMain{
  left: 20px !important;
}
.recommendations-and-cards {
  background-image: url("../../assets/images/back.png");
  background-size: contain;
  background-repeat: no-repeat;
  overflow-x: hidden;
  padding-left: 200px;
  padding-right: 200px;
  margin: 0;
}

.student-name {
  font-size: 26px;
  font-weight: bold;
  color: #434343;
  margin-bottom: 19px;
}

.info-about {
  color: #8e8e8e;
  font-size: 18px;
  margin-left: 15px;
}
.another-class{
  margin-left: 0 !important;
  width:280px !important;
}
.text-font{
  font-size: 12px !important;
}
.myClass{
  position: relative !important;
  left:50px !important;
}
.aboutContainer {
  margin-bottom: 8px;
}

.editContainer {
  margin-top: 30px;
}

.editText {
  font-size: 18px;
  cursor: pointer;
}

.note {
  color: #8e8e8e;
  font-size: 14px;
  padding-top: 36px;
  font-weight: 400 !important;
}

.upgrade-and-video-mobile {
  display: none;
}

.cards-container {
  position: relative;
}

.chat-icon {
  position: absolute;
  right: -10%;
  top: 25%;
}

.neither-container {
  padding-bottom: 18px;
  font-weight: 400;
}
.profileWrap{padding-right: 10px;}
.profileWrap .image-contanainer{width: 142px; height: 142px;}
.profileWrap .image-contanainer img{width: 100%; height: 100%; border-radius: 50%;}
.uploadImgWrap{position: relative;
  width: 110px;
  height: 110px;
  border: 5px solid #cc6827;
  border-radius: 50%;}
  .uploadImgWrap:hover{cursor: pointer;}
.uploadImgWrap img{width: 100%; height: 100%; border-radius: 50%;}
.uploadImgWrap input[type="file"]{cursor: pointer; position: absolute; width: 100%; height: 100%; left: 0; top:0; opacity: 0; border-radius: 50%;}
.materialSlider .lazy-load-image-background{display: block !important;}
.materialSlider .lazy-load-image-background img{width: 100%;min-height: 240px;}
.socialBtn{display: flex !important;
  justify-content: center;
  align-items: center !important;
  border: 1px solid rgb(142, 142, 142) !important;
  border-radius: 14px !important;
  padding: 12px 20px;
  width: 100%;
  box-shadow: none !important;
  margin-bottom: 15px; height: 51px;color: #434343 !important;font-size: 18px !important;
  font-weight: 500 !important; }
  .socialBtn>span{font-weight: 500 !important; letter-spacing: 0.6px;font-family: 'Ubuntu', 'sana-serif' !important;}
  .socialBtn .fa{margin-right: 17px;
    width: 22px;
    height: 22px;
    background: #1877F2;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;font-weight: 700 !important;
    letter-spacing: 1px}
@media (max-width: 386px) {
  .Uni {
    width: 10px !important;
  }

  /* .info-about {
    font-size: 10px !important;
  } */

  .chat-icon {
    position: absolute;
    top: 100%;
  }

  .student-name {
    font-size: 18px;
  }

  /* .image-container {
    display: none;
  } */
  .leafWrap{
    display: none !important;
  }
  .pr-image {
    width: 95px;
  }

  .name {
    width: 100%;
    justify-content: center;
  }

  .note {
    text-align: left;
  }

  .upgrade {
    /* display: none; */
    font-size: 14px !important;
  }

  .neither-container {
    margin-bottom: 20px !important;
  }

  .recom {
    font-size: 29px;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .break {
    display: none;
  }

  .name-image {
    border: 1px solid red;
  }
}

@media (max-width: 1250px) {
  .profile-info {
    padding-left: 100px;
    padding-right: 100px;
    padding-top:8%;
    padding-bottom: 8%;
  }

  .recommendations-and-cards {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-width: 1000px) {
  .profile-info {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
  }

  .recommendations-and-cards {
    padding-left: 50px;
    padding-right: 50px;
  }

  .student-name {
    font-size: 18px;
  }

  .info-about {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .profile-info {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 50px;
  }

  .recommendations-and-cards {
    padding-left: 24px;
    padding-right: 24px;
  }

  .infoCcontainer {
    margin-left: 25px;
  }

  .info-about {
    font-size: 12px;
  }

  .upgrade {
    font-size: 17px;
  }

  .recom {
    font-size: 32px;
  }

  .student-name {
    font-size: 16px;
    margin: 3px;
  }
  .profileWrap .editText {
    font-size: 14px !important;
}
.flip-card {
   
    width: 85%;
    min-height: 250px;
    text-align: center;
    
  
}

.flip-card-front p {
    font-size: 14px !important;
}
.uploadImgWrap{
  width: 90px;
  height: 90px;

}

}

@media (max-width: 600px) {
  /* .image-container {
    display: none;
  } */

  .cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .recom {
    font-size: 26px;
}
.uploadImgWrap{
  width: 80px;
  height: 80px;

}
}

@media(max-width:1600px){
  /* .profileWrap{max-width: 500px;} */
}
@media(max-width:1500px){
  .profileWrap{max-width: 46%;}
  .leafWrap{left: 0%;
    top: 14%;}
    .leafWrap span img{width: 148px;}
    .profileWrap .info-about {
      font-size: 17px;}
      .profileWrap .image-contanainer {
        width: 130px;
        height: 130px;
       
    }
    .infoCcontainer {
      margin-left: 15px;
  }
}
@media(max-width:1004px){
  .new-width{
    position: absolute;
    left:-300px;
  }
}
@media(max-width:830px){
  .new-set{
    position: relative;
left: -30px;
  }
}
@media(max-width:767px){

}
@media(max-width:1199px){
  .profileWrap .name{flex-direction: column;}
  .profileWrap {
    max-width: 49%;
}
.react-responsive-modal-modal {
   
    padding: 45px 55px !important;
    max-width: 635px !important;
}
}
@media(max-width:991px){
  .profileWrap .info-about {
    font-size: 15px;
    margin:0;
}
.profileWrap {
  padding-right: 25px;
}
.profileWrap .editText {
  font-size: 16px;
}
.profileWrap .editContainer {
  margin-top: 20px;
}
.flip-card-front p {
    font-size: 16px !important;
}
.flipMain {
    margin-bottom: 40px;
     gap: 0%;
}
.flip-card-back.p-4 {
    padding: 0;
    padding-left: 0px;
}
.flip-card p {
  padding: 0 !important;
}
.flip-card-back p {
    font-size: 15px !important;
    padding: 5px 10px !important;
}
.backSubBox p{
   font-size: 13px !important;
    padding: 5px 10px;
}
 .profileFlex{flex-direction: column;}
}

@media(max-width:767px){
  .profileWrap{max-width: 100%; padding-right: 0;}
 
  /* .profileWrap .name {
    flex-direction: row;
} */

/* .profileWrap .image-container{display: none;} */
.exploreBtn{
  font-size: 16px;
}
 .profileWrap .info-about {
    font-size: 14px;
}
.profileWrap .image-contanainer img {
    width: 100%;
    height: 100%;
   
}
.infoCcontainer {
    margin-left: 4px;
}
.profileWrap .image-contanainer {
    width: 100px;
    height: 100px;
}

.flip-card-back p {
    font-size: 13px !important;
   
}
.backSubBox p{
  font-size: 13px !important;
}
.contentColor div {
    font-size: 13px;
}

}

@media (max-width:575px){
  .profile-info {
    
    padding-top: 40px;
}
.flip-card-front p {
    font-size: 14px !important;
}
.flip-card {
   
    height: 220px;
}
.uploadImgWrap{
  width: 70px;
  height: 70px;
  margin-bottom: 5px;

}
.flip-card-back p {
    font-size: 12px !important;
   
}
.backSubBox p{
  font-size: 12px !important;
}
.contentColor div {
    font-size: 12px;
}
}

@media(max-width:480px){
 
.profileWrap .editText {
  font-size: 14px ;
}
.exploreBtn{
  font-size: 14px;
}
}
@media(max-width:1023px){
  .secSearch {
    /* display: block !important; */
    /* padding-bottom: 5px; */
  }
}
@media(max-width:1199px){
  .fixing{
    position: relative;
    left: -165px !important;
  }
}
@media(max-width:900px){
  .fixing{
    position: relative;
    left: -180px !important;
  }
  /* .toolTipMain{
    left: 40px !important;
  } */
}
@media(max-width:840px){
  .fixing{
    position: relative;
    left: -200px !important;
  }
  /* .toolTipMain{
    left: -60px !important;
  } */

}
@media(max-width:521px){
 .new-width{
  left: -220px;
 }
 /* .toolTipMain{
  left: 40px !important;
} */
}
@media(max-width:440px){
 
  /* .toolTipMain{
   left: 20px !important;
 } */
 }
