.nav-item {
    padding-right: 40px;
    cursor: pointer;
}

/* .searchWrap {
    width: 400px;
} */
.loaderCenter {
    display: block;
    margin: 0 auto;
    width: max-content;

}

.searchWrap.adminSearchWrap .css-1jxare7-control {
    background: #ffff;
}

.searchWrap .css-319lph-ValueContainer, .searchWrap .css-1d8n9bt  {
    /* max-width: 300px; */
}

.respHeader .nav-item {
    /* padding: 5px !important; */
    padding-right: 10px;
}

.appendHeader {
    display: none;
    font-size: 0.9em;
    z-index: 999;
}

@media (max-width: 768px) {
    .searchWrap.adminSearchWrap .css-1jxare7-control {
        height: 40px;
    }

    .searchWrap .css-319lph-ValueContainer {
        max-width: 74.7%;
    }
}
