/* .react-responsive-modal-modal {
    border-radius: 25px !important;
    padding: 50px 64px !important;
    max-width: 634px !important;
    overflow-y: unset !important;
} */

.parent-modal-div .react-responsive-modal-modal {
    border-radius: 25px !important;
    padding: 50px 64px !important;
    /* max-width: 634px !important; */
    /* overflow-y: unset !important; */
    min-width: 60% !important;
    max-width: 60% !important;
  }
@media only screen and (min-width: 300px) and (max-width: 767px) {
    .react-responsive-modal-modal {
        margin: 1.2rem auto !important;

        padding: 50px 30px !important;
        max-width: 300px !important;
    }
}

.react-responsive-modal-closeButton {
    top: 20px !important;
    right: 20px !important;
}

.react-responsive-modal-overlay {
    background: linear-gradient(162deg, rgba(255, 196, 68, 0.2), rgba(243, 111, 86, 0.2)) !important;
    backdrop-filter: blur(20px);
}