#session-container::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
}

.table-curved {
    width: 100%;
    height: 500px;
}

/* .slotCheck input {
    width: 100%;
    height: 50px;
}

.slotCheck input[type="checkbox"]:checked {
    color: black;
    background-color: black;

} */

.slotCheck{margin-bottom:0;width:75px;height:39px;position:relative; display: inline-block;}
.slotCheck>span{border-radius:6px;position:absolute;width:100%;height:100%;left:0;top:0;background:#F1F1F5;}
.slotCheck input[type="checkbox"]:checked+span{background:#cc6828;}
.tutorTable td{text-align: center;}
.tutorTable{margin-bottom: 100px;overflow-x:auto;}
#session-container::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

#session-container::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #d8d8d8;
}

.checkbox .input-assumpte {
    display: none;
}

.input-assumpte + label::before {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border: 1px solid #8e8e8e;
    border-radius: 4px;
    /* display: inline-block; */
    color: #fff;
    transition-duration: 0.3s;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    content: "✓";
    margin-right: 12px;
}

.input-assumpte:checked + label::before {
    background-color: #cc6828;
    border: none;
}

.addSessBtn {
    width: 160px;
    height: 40px;
    color: #cc6828;
    background: transparent;
    border: 2px solid #cc6828;
    line-height: 16px;
}

.addSessBtn:hover {

    /* color:rgba(67, 67, 67, var(--tw-text-opacity)); */
    /* background:#cc6828; */
    /* border:2px solid rgba(67, 67, 67, var(--tw-text-opacity)); */
    /* color: rgba(67, 67, 67, var(--tw-text-opacity)); */
    opacity: 0.8;
}

/* radio css */


/* .radioRow input[type="radio"]:checked + label span {
  background-color: #cc6828; 
  box-shadow: 0px 0px 0px 2px white inset;
  border:#cc6828;
}

.radioRow input[type="radio"]:checked + label{
   color: #cc6828; 
} */
.greenText {
    color: #267864;
}
@media (max-width:767px){
    
    .tutorTable th{font-size: 14px;}
    .slotCheck {
        width: 50px;
        height: 30px;
    }
    .tutorTable th {
        font-size: 12px;
    }
    .tsTableMain th {
    min-width: 70px !important;
}
.tutorTable td{
    min-width: 70px !important;
}
}
/* @media (max-width:767px){
    
    
    .slotCheck {
        width: 35px;
        height: 25px;
    }
} */

@media (max-width:575px){
     .tsTableMain th {
    min-width: 60px !important;
}
.tutorTable td{
    min-width: 60px !important;
}
}
