.react-responsive-modal-modal {
    border-radius: 25px !important;
    padding: 50px 64px !important;
    /* max-width: 634px !important; */
}
.signup-Modal .react-responsive-modal-modal {
    border-radius: 25px !important;
    padding: 50px 64px !important;
    max-width: 80% !important;
}
.signup-Modal{
    width: 80% !important;
    background-color: red !important;
}
@media only screen and (min-width: 300px) and (max-width: 767px) {
    .react-responsive-modal-modal {
        margin: 1.2rem auto !important;

        padding: 50px 30px !important;
        max-width: 450px !important;
    }
}

.PhoneInput input {
    height: 100%;
    width: 100%;
}

.react-responsive-modal-closeButton {
    top: 20px !important;
    right: 20px !important;
}

.react-responsive-modal-overlay {
    background: linear-gradient(162deg, rgba(255, 196, 68, 0.2), rgba(243, 111, 86, 0.2)) !important;
    backdrop-filter: blur(20px);
}

.dropzoneSection {
    border: 2px dashed;
    height: 247px;
    width: 474px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.dropzoneInner {
    height: 100%;
    width: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    /* border: 0.5px solid rgb(177, 171, 171); */
}
@media (max-width: 1199px){
   .react-responsive-modal-modal{
    padding: 45px 55px !important;
   }
}
@media (max-width: 991px){
   .react-responsive-modal-modal{
    padding: 40px 50px !important;
    max-width: 100%;
    width: 90% !important;
   }
   .react-responsive-modal-modal p {
    font-size: 15px;
}
}
@media (max-width: 767px){
   .react-responsive-modal-modal{
    padding: 35px 45px !important;
   }
   .react-responsive-modal-modal p {
    font-size: 14px;
}
}
@media (max-width: 575px){
   .react-responsive-modal-modal{
    padding: 35px 40px !important;
    /* max-width:315px !important; */
   }
   .react-responsive-modal-modal p {
    font-size: 13px;
}
}