.search-container {
  background-color: #fff;
  position: relative;
  background: #fffaf3;
  box-sizing: border-box;
  border: 1px solid rgba(67, 67, 67, 0.5);
  border-radius: 14px;
  margin-top: 20px;
}

.search-box {
  background-color: #fffaf3;
  padding: 15px 20px;
  height: 51px;

  opacity: 0.5;
  width: 265px;
}

.text-input {
  padding-left: 20px;
  height: 51px;
  width: 100%;
}

.text-input-container {
  border: 1px solid #D8D8D8;
  border-radius: 14px;
  max-width: 100%;
    overflow: hidden;
}

.text-input-container-error {
  border: 1px solid #e54646;
  border-radius: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formik-error {
  color: #e54646;
  font-size: 0.875rem;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}