.dropzoneImageSection {
    border: 2px dashed;
    height: 450px;
    width: 745px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.dropzoneImageInner {
    height: 100%;
    width: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    /* border: 0.5px solid rgb(177, 171, 171); */
}

.selectedImage {
    height: 450px;
    width: 745px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: relative;
}

.shadow {
    box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.12);
    border-radius: 14px !important;
    padding: 20px !important;
}



.__react_component_tooltip.show {
    opacity: 1 !important;
}