#session-container::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
}

.table-curved {
    width: 100%;
    height: 500px;
}

/* .slotCheck input {
    width: 100%;
    height: 50px;
}

.slotCheck input[type="checkbox"]:checked {
    color: black;
    background-color: black;

} */
.holidayList{padding: 0; margin: 0; list-style-type: none;max-width: 500px;}
.holidayList li{background: #fffaf3;
    margin-bottom: 15px; font-size: 16px;
    padding: 8px 15px; display: flex; justify-content: space-between; align-items: center;}
    .holidayList li button{background: #cc6828;
        color: #fff;
        width: 25px;
        height: 25px;
        display: inline-flex;
        justify-content: center;
        align-items: end;
        border-radius: 5px;
        font-size: 18px;}
        .holidayList li b{color: #cc6828;}
        .holidayCal{align-self: baseline;
            border: 1px solid #000;
            border-radius: 5px;
            padding: 3px 20px 3px 26px;
            font-size: 15px;}
.slotCheck{margin-bottom:0;width:75px;height:39px;position:relative; display: inline-block;}
.slotCheck>span{border-radius:6px;position:absolute;width:100%;height:100%;left:0;top:0;background:#F1F1F5;}
.slotCheck input[type="checkbox"]:checked+span{background:#cc6828;}
.tutorTable td{text-align: center;}
.tutorTable{margin-bottom: 100px;overflow-x:auto;}
.slotCheck.holiday>span,.slotCheck.holiday input[type="checkbox"]:checked + span{background: #f4aaaa;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fd0707;font-size: 12px;font-weight: bold;}
#session-container::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

#session-container::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #d8d8d8;
}

.checkbox .input-assumpte {
    display: none;
}

.input-assumpte + label::before {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border: 1px solid #8e8e8e;
    border-radius: 4px;
    /* display: inline-block; */
    color: #fff;
    transition-duration: 0.3s;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    content: "✓";
    margin-right: 12px;
}

.input-assumpte:checked + label::before {
    background-color: #cc6828;
    border: none;
}

.addSessBtn {
    width: 160px;
    height: 40px;
    color: #cc6828;
    background: transparent;
    border: 2px solid #cc6828;
    line-height: 16px;
}

.addSessBtn:hover {

    /* color:rgba(67, 67, 67, var(--tw-text-opacity)); */
    /* background:#cc6828; */
    /* border:2px solid rgba(67, 67, 67, var(--tw-text-opacity)); */
    /* color: rgba(67, 67, 67, var(--tw-text-opacity)); */
    opacity: 0.8;
}

/* radio css */


/* .radioRow input[type="radio"]:checked + label span {
  background-color: #cc6828; 
  box-shadow: 0px 0px 0px 2px white inset;
  border:#cc6828;
}

.radioRow input[type="radio"]:checked + label{
   color: #cc6828; 
} */
.greenText {
    color: #267864;
}
.tsTableMain th{
      
        min-width:85px !important;
    }
/* media query fk */
@media(max-width:1199px){
    .tsTableMain th{
        font-size: 15px !important;
      
    }
    .timeSlotHead {
    padding: 35px 0px 30px;
}
.tSlotHeadLeft p:nth-of-type(1){
    font-size: 24px !important; 
}
.tSlotHeadLeft p:nth-of-type(2){
    font-size: 16px !important; 
}
.holidayCal {
   
    padding: 3px 20px !important;
    font-size: 14px !important;
}
.holidayList li {
   
    font-size: 15px !important;

   
}
.holidayList li button {
  
    width: 22px !important;
    height: 22px !important;
    font-size: 17px !important;
}
.bookTimeText{
    font-size: 24px !important;
    margin-bottom:15px !important;
}
.availSlotText{
    font-size: 17px !important;
}
.addSessBtn {
    width: 140px !important;
    height: 35px !important;
    font-size: 15px !important;
 
}
.tutorTable {
    margin-bottom: 60px !important;
  
}
.headDropSec.w-46.md\:w-46 img {
    /* height: auto !important; */
    width: 45px !important;

}
.contCretP{
    font-size: 15px;
}
}

@media (max-width:991px){
.tsTableMain th{
        font-size: 14px !important;
      
    }
    .timeSlotHead {
    padding: 30px 0px 25px;
}
.tSlotHeadLeft p:nth-of-type(1){
    font-size: 22px !important; 
    margin-bottom: 15px !important;
}
.tSlotHeadLeft p:nth-of-type(2){
    font-size: 14px !important; 
}
.holidayCal {
   
    padding: 3px 25px !important;
    font-size: 13px !important;
}
.holidayList li {
   
    font-size: 14px !important;

   
}
.holidayList li button {
  
    width: 20px !important;
    height: 20px !important;
    font-size: 16px !important;
}
.bookTimeText{
    font-size: 22px !important;
    margin-bottom:12px !important;
}
.availSlotText{
    font-size: 16px !important;
}
.addSessBtn {
    width: 120px !important;
    height: 30px !important;
    font-size: 14px !important;
 
}
.tutorTable {
    margin-bottom: 40px !important;
  
}
.headDropSec.w-46.md\:w-46 img {
    /* height: auto !important; */
    width: 40px !important;

}
.headDropSec img {
    height: 37px;
}
.contCretP{
    font-size: 14px;
}
}



/* media query fk // */
@media (max-width:767px){
    
    .tutorTable th{font-size: 14px;}
    .slotCheck {
        width: 50px;
        height: 30px;
    }
.tutorTable th {
        font-size: 12px;
    }
    .slotCheck {
        width: 55px;
    height: 25px;
    }
    /* fk css  */

.tsTableMain th{
        font-size: 13px !important;
      
    }
    .timeSlotHead {
    padding: 25px 0px 20px;
}
.tSlotHeadLeft p:nth-of-type(1){
    font-size: 20px !important; 
    margin-bottom: 12px !important;
}
.tSlotHeadLeft p:nth-of-type(2){
    font-size: 13px !important; 
}
.holidayCal {
   
    padding: 3px 25px !important;
    font-size: 12px !important;
}
.holidayList li {
   
    font-size: 13px !important;

   
}
.holidayList li button {
  
    width: 18px !important;
    height: 18px !important;
    font-size: 15px !important;
    line-height: 18px;
}
.bookTimeText{
    font-size: 20px !important;
    margin-bottom:10px !important;
}
.availSlotText{
    font-size: 14px !important;
}
.addSessBtn {
    width: 120px !important;
    height: 30px !important;
    font-size: 13px !important;
 
}
.tutorTable {
    margin-bottom: 20px !important;
  
}
.tutorTable td {
    
    min-width: 80px;
}

.headDropSec.w-46.md\:w-46 img {

    width: 37px !important;

}
.contCretP{
    font-size: 13px;
}

    /* fk css // */
}


@media (max-width: 575px){
  
    .tsTableMain th{
        font-size: 12px !important;
      
    }
    .timeSlotHead {
    padding: 25px 0px 20px;
}
.tSlotHeadLeft p:nth-of-type(1){
    font-size: 18px !important; 
    margin-bottom: 10px !important;
}
.tSlotHeadLeft p:nth-of-type(2){
    font-size: 12px !important; 
    white-space: nowrap;
}
.holidayCal {
    padding: 3px 5px 3px 25px !important;
    font-size: 12px !important;
}
.holidayList li {
   
    font-size: 12px !important;

   
}
.holidayList li button {
  
    width: 15px !important;
    height: 15px !important;
    font-size: 13px !important;
    line-height: 17px;
}
.bookTimeText{
    font-size: 18px !important;
    margin-bottom:8px !important;
}
.availSlotText{
    font-size: 13px !important;
}
.addSessBtn {
    width: 120px !important;
    height: 27px !important;
    font-size: 12px !important;
 
}
.tutorTable {
    margin-bottom: 20px !important;
  
}
.tutorTable td {
    
    min-width: 55px;
}
.slotCheck {
    width: 51px !important;
    height: 25px !important;
    font-size: 12px !important;
}
.tsTableMain th {
    min-width: 55px !important;
}
.headDropSec.w-46.md\:w-46 img {

    width: 36px !important;

}
}

